import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';
import SponsorGroupFilter from '@layouts/SponsorGroupFilter';

//ICON
import { AddBoxOutlined, Edit } from '@mui/icons-material';

const MODULE = 'user';

const Admin = () => {
    const [data, setData] = useState([]);
    const [option, setOption] = useState({});
    const [tableLoading, setTableLoading] = useState(false);
    const [searchData, setSearchData] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [statusList, setStatusList] = useState([]);
    const [leaderRankList, setLeaderRankList] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const [selectedGenealogyType, setSelectedGenealogyType] = useState('individual');
    const [searchValue, setSearchValue] = useState(''); // username

    const checkHandler = (event) => {
        setSearchValue(event.target.value);
    }

    const handleGenealogyTypeChange = (event) => {
        setSelectedGenealogyType(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchValue('');
        setSelectedGenealogyType(selectedGenealogyType);
        callApi();
    };

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData])

    const callApi = () => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
            search: searchValue, // username
            genealogyType: selectedGenealogyType
        }
        getUrl(`/members`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setRowCount(response.data.listing.total);
                setData(response.data.listing.data);
            } else {
                addAlert("", response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    };

    useEffect(() => {
        setTableLoading(true);
        getUrl(`/member-options`).then(response => {
            setTableLoading(false);
            if (response.status) {
                setOption(response.data);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let newStatusList = [];
        _.map(option?.statusList, (status, key) => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: key.toString() });
        })
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [option?.statusList, i18n.language]);

    useEffect(() => {
        if (_.size(option?.leadershipRankList) > 0) {
            let newLeaderRankList = [];
            _.map(option?.leadershipRankList, rank => {
                let textDisplay = rank.name[i18n.language] ? rank.name[i18n.language] : rank.name.en;
                newLeaderRankList.push({ text: textDisplay, value: rank.id });
            })
            setLeaderRankList(newLeaderRankList);
        }
    }, [option?.leadershipRankList, i18n.language])

    const columns = useMemo(() => ([
        {
            header: `${t('table.actions')}`,
            accessorKey: 'actions',
            Cell: ({ cell }) => (
                _.includes(permissions, `member-edit`) ?
                    <Link underline='none' to={`/member-edit/${cell.row.original.id}`} component={RouterLink}>
                        <Edit style={{ color: theme.palette.button.main }} />
                    </Link>
                    : null
            ),
            enableSorting: false,
            enableColumnFilter: false,
            maxSize: 50,
            hideExport: true,
        },
        {
            header: `${t(`${MODULE}.username`)}`,
            accessorKey: 'username',
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            header: `${t(`${MODULE}.name`)}`,
            accessorKey: 'name',
        },
        {
            header: `${t(`${MODULE}.email`)}`,
            accessorKey: 'email',
        },
        {
            header: `${t(`${MODULE}.mobile`)}`,
            accessorKey: 'mobile',
            Cell: ({ cell }) => (
                <Typography>{cell?.row?.original?.mobile || '-'}</Typography>
            )
        },
        /* {
            accessorKey: 'leadership_rank',
            header: `${t(`${MODULE}.leadershipRank`)}`,
            filterFn: 'equals',
            filterSelectOptions: leaderRankList,
            filterVariant: 'select',
            maxSize: 20,
            Cell: ({ cell }) => (
                <p style={{ textAlign: 'center' }}>{cell.row.original.max_mrank?.name[i18n.resolvedLanguage] || '-'}</p>
            ),
        }, */
        {
            id: 'mrank.name.' + i18n.language,
            accessorFn: (row) => (row?.mrank?.name?.[i18n.language] ? row?.mrank?.name?.[i18n.language] : '-'),
            header: `${t(`${MODULE}.mrank`)}`,
            filterFn: 'equals',
            filterSelectOptions: option?.leadershipRankList?.map(mrank => ({ text: mrank.name[i18n.language], value: mrank.id.toString() })) || [],
            filterVariant: 'select',
            Cell: ({ cell }) => (
                // Update the logic to display the name property of rank
                cell?.row?.original?.mrank?.name?.[i18n.language]   
                    ? <>{cell.row?.original?.mrank?.name[i18n.language]}</>
                    : <>-</>
            ),
        },
        {
            id: 'cmrank.name.' + i18n.language,
            accessorFn: (row) => (row?.cmrank?.name?.[i18n.language] ? row?.cmrank?.name?.[i18n.language] : '-'),
            header: `${t(`${MODULE}.cmrank`)}`,
            filterFn: 'equals',
            filterSelectOptions: option?.leadershipRankList?.map(cmrank => ({ text: cmrank.name[i18n.language], value: cmrank.id.toString() })) || [],
            filterVariant: 'select',
            Cell: ({ cell }) => (
                cell?.row?.original?.cmrank?.name?.[i18n.language]   // Check if the nested key exists
                    ? <>{cell.row?.original?.cmrank?.name[i18n.language]}</>
                    : <>-</>
            ),
        },
        /* {
            accessorKey: 'always_active',
            header: `${t(`${MODULE}.alwaysActive`)}`,
            filterFn: 'equals',
            filterSelectOptions: ["0", "1"],
            filterVariant: 'select',
            maxSize: 20,
            Cell: ({ cell }) => (
                <p style={{ textAlign: 'center' }}>{cell.row.original.data?.always_active || '0'}</p>
            ),
        }, */
        {
            accessorKey: 'status',
            header: `${t(`${MODULE}.status`)}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            maxSize: 20,
            Cell: ({ cell }) => (
                <p style={{ textAlign: 'center' }}>{t(`${MODULE}.statusInfo.${cell.row.original.status}`)}</p>
            ),
        },
        {
            accessorKey: 'wallets',
            header: `${t(`${MODULE}.wallets`)}`,
            enableSorting: false,
            maxSize: 50,
            Cell: ({ cell }) => (
                <div>
                    {
                        cell.row.original.wallets?.general ?
                            <div>
                                <p style={{ wordBreak: 'break-all', fontWeight: 600 }}>{t('user.withdrawal') + ' [' + cell.row.original.wallets.general.network.toUpperCase() + '] :'}</p>
                                <p>{cell.row.original.wallets.general.address}</p>
                            </div>
                            : null
                    }
                    {
                        _.map(cell.row.original.wallets?.bicpay, (data, network) => {
                            return (
                                <div key={data.address}>
                                    <p style={{ wordBreak: 'break-all', fontWeight: 600 }}>{network.toUpperCase() + ' : '}</p><p>{data.address}</p>
                                </div>
                            )
                        })
                    }
                </div>
            ),
        },
        {
            header: `${t(`${MODULE}.country`)}`,
            accessorKey: 'country',
        },
        {
            header: `${t(`general.createdAt`)}`,
            accessorKey: 'created_at',
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList, leaderRankList]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.member`)}</b></Typography>
                    {
                        _.includes(permissions, "member-create") &&
                        <Link underline='none' to={`/member-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                    }
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.member`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <SponsorGroupFilter
                selectedGenealogyType={selectedGenealogyType}
                searchValue={searchValue}
                checkHandler={checkHandler}
                callApi={callApi}
                handleGenealogyTypeChange={handleGenealogyTypeChange}
                handleClearSearch={handleClearSearch}
            />
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'MemberList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                columnVisibility={{ [MODULE + '.' + i18n.language]: false }}
            />
        </>
    )
}

export default Admin;