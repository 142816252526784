import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FiArrowLeft } from "react-icons/fi";

import { getUrl, postUrl, putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

export default function Detail() {
    const styles = useStyles();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [state, setState] = useState({
        type: '',
        code: '',
        details: '',
    });
    const [inputErrors, setInputErrors] = useState({});
    const theme = useTheme();
    let navigate = useNavigate();
    let { id } = useParams();
    const location = useLocation();
    const formType = location.pathname === `/wallet-setting-add` ? "add" : "edit";

    useEffect(() => {
        if (id) {
            getUrl(`/wallet-setting/${id}`)
                .then(response => {
                    if (response.status && response.data) {
                        setState(response.data);
                    } else {
                        addAlert("", t('error.contactSupport'), 'error', '');
                    }
                })
                .catch(error => {
                    addAlert('', error.message || t('error.contactSupport'), 'error', '');
                });
        } else {
            setState({
                type: '',
                code: '',
                details: '',
            });
        }
        // eslint-disable-next-line
    }, [id]);

    const submitData = () => {
        setLoading(true);
        setInputErrors([]);
        const updateData = {
            type: state.type,
            code: state.code,
            details: state.details,
        };
        if (formType === 'edit' && id) {
            putUrl(`/wallet-setting/${id}`, updateData).then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert('', t('success.editSuccess'), 'success', '');
                } else {
                    setInputErrors(response.errors);
                    addAlert('', t('error.editError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            postUrl('/wallet-setting', updateData).then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert('', t('success.createSuccess'), 'success', '');
                    navigate(`/wallet-setting-edit/${response.data.id}`, { replace: true });
                } else {
                    setInputErrors(response.errors);
                    addAlert('', t('error.createError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    return (
        <div>
            <Link underline='none' component={RouterLink} to={`/wallet-setting`}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.backTo')}{t('title.walletSetting')}</Typography>
                </div>
            </Link>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 10 }}>
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{formType === 'edit' ? t('title.walletSettingEdit') : t('title.walletSettingAdd')}</b></Typography>
                <div style={{ paddingBottom: 15 }}>
                    <div>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                                {t('title.dashboard')}
                            </Link>
                            <Link underline="hover" color="inherit" component={RouterLink} to="/wallet-setting">
                                {t('title.walletSetting')}
                            </Link>
                            <Typography >{formType === 'edit' ? t('title.walletSettingEdit') : t('title.walletSettingAdd')}</Typography>
                        </Breadcrumbs>
                    </div>
                </div>
            </Grid>
            <div style={{ paddingBottom: '5%' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('setting.settingType')}
                                variant="outlined"
                                value={state && state.type ? state.type : ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.type ? inputErrors.type : ''}
                                error={inputErrors && inputErrors.type ? true : false}
                                onChange={({ target }) => setState({ ...state, type: target.value })}
                                disabled={formType === 'edit'}  // Disable only when editing
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('setting.settingCode')}
                                variant="outlined"
                                value={state && state.code ? state.code : ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.code ? inputErrors.code : ''}
                                error={inputErrors && inputErrors.code ? true : false}
                                onChange={({ target }) => setState({ ...state, code: target.value })}
                                disabled={formType === 'edit'}  // Disable only when editing
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('setting.settingValue')}
                                variant="outlined"
                                value={typeof state.details === 'object' ? JSON.stringify(state.details) : state.details}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.details ? inputErrors.details : ''}
                                error={inputErrors && inputErrors.details ? true : false}
                                onChange={({ target }) => {
                                    let newValue = target.value;
                                    try {
                                        // Attempt to parse the value as JSON
                                        const parsedValue = JSON.parse(target.value);
                                        // Update the value only if parsing succeeds
                                        if (typeof parsedValue === 'object' && parsedValue !== null) {
                                            newValue = parsedValue;
                                        }
                                    } catch (error) {
                                        // Parsing failed, keep the original value
                                    }
                                    setState({ ...state, details: newValue });
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link underline='none' to={`/wallet-setting`} component={RouterLink}>
                        <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                    </Link>
                    <Button size="large" variant="contained" className={styles.buttonStyle} onClick={() => submitData()}>{formType === 'edit' ? t('button.edit') : t('button.save')}</Button>
                </Box>
            </div>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}));