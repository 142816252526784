import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { postUrl } from '@utils/ApiAction';

import { Link, TextField, Box, Button, Breadcrumbs, Grid, Typography, InputAdornment, IconButton , FormControl , InputLabel , Select , MenuItem , FormHelperText } from '@mui/material';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import BackToPrevious from '@layouts/BackToPrevious';
import COUNTRIES from '@utils/CountryList';

const MODULE = 'user';
const INITIAL_STATE = {
    username: '',
    name: '',
    mobile: '',
    email: '',
    country: '',
    referralName:'',
    placementName:'',
    placementPosition:'',
    password: '',
    passwordConfirmation: '',
    roleType: ['member'],
}

const Create = () => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState({});
    const [showPassword, setShowPassword] = useState({
        password: false,
        passwordConfirmation: false,
    });
    const [state, setState] = useState(INITIAL_STATE);
    const { addAlert, setLoading } = useNotificationLoading();
    const POSITION = [
        { 'code': 'left', 'value': 1, label: t('general.left') },
        { 'code': 'right', 'value': 2, label: t('general.right') }
    ];

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            username: state.username,
            name: state.name,
            email: state.email,
            mobile: state.mobile,
            country: state.country,
            password: state.password,
            password_confirmation: state.passwordConfirmation,
            role_type: state.roleType,
            referral_name:state?.referralName,
            placement_name:state?.placementName,
            placement_position:state?.placementPosition
        }
        postUrl(`/members`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState(INITIAL_STATE);
                addAlert('', t('success.createSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.memberAdd`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/members`}>
                            {t(`title.member`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.memberAdd`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingTop: '5%', paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.username`)}
                            variant="outlined"
                            value={state.username}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            name="username"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.name`)}
                            variant="outlined"
                            value={state.name}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                            error={inputErrors && inputErrors.name ? true : false}
                            name="name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.mobile`)}
                            variant="outlined"
                            value={state.mobile}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                            error={inputErrors && inputErrors.mobile ? true : false}
                            name="mobile"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.email`)}
                            variant="outlined"
                            type="email"
                            value={state.email}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            name="email"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <TextField
                            variant="outlined"
                            name='referralName'
                            label={t(`${MODULE}.referralName`)}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.referral_name ? inputErrors.referral_name : ''}
                            error={inputErrors && inputErrors.referral_name ? true : false}
                            InputLabelProps={{ shrink: true }}
                            value={state.referralName}
                        />
                    </Box>
                </Grid>
                {
                    _.size(COUNTRIES) > 0 &&
                    <Grid item xs={12} sm={6} md={4}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth error={inputErrors && inputErrors.country ? true : false}>
                                <InputLabel>{t(`${MODULE}.country`)}</InputLabel>
                                <Select
                                    value={state?.country || ''}
                                    label={t(`${MODULE}.country`)}
                                    name="country"
                                    onChange={handleChange}
                                    displayEmpty
                                >
                                    <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.country`)} `} --- </MenuItem>
                                    {
                                        _.map(COUNTRIES, (data, key) => {
                                            return <MenuItem key={key} value={data.value}>{data.label}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.country &&
                                    <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors?.country}</FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                }
                {/* <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <TextField
                            variant="outlined"
                            name='placementName'
                            label={t(`${MODULE}.placementName`)}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.placement_name ? inputErrors.placement_name : ''}
                            error={inputErrors && inputErrors.placement_name ? true : false}
                            InputLabelProps={{ shrink: true }}
                            value={state.placementName}
                        />
                    </Box>
                </Grid> */}
                {/* {
                    _.size(POSITION) > 0 &&
                    <Grid item xs={12} sm={6} md={4}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth error={inputErrors && inputErrors.placement_position ? true : false}>
                                <InputLabel>{t(`${MODULE}.placementPosition`)}</InputLabel>
                                <Select
                                    value={state?.placementPosition || ''}
                                    label={t(`${MODULE}.placementPosition`)}
                                    name="placementPosition"
                                    onChange={handleChange}
                                    displayEmpty
                                >
                                    <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.placementPosition`)} `} --- </MenuItem>
                                    {
                                        _.map(POSITION, (data, key) => {
                                            return <MenuItem key={key} value={data.value}>{data.label}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.placement_position &&
                                    <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors?.placement_position}</FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                } */}
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <TextField
                            name="password"
                            label={t(`${MODULE}.password`)}
                            type={showPassword.password ? 'text' : 'password'}
                            value={state.password}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                            error={inputErrors && inputErrors.password ? true : false}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('password')}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword.password ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <TextField
                            name="passwordConfirmation"
                            label={t(`${MODULE}.confirmPassword`)}
                            type={showPassword.passwordConfirmation ? 'text' : 'password'}
                            value={state.passwordConfirmation}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                            error={inputErrors && inputErrors.password_confirmation ? true : false}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('passwordConfirmation')}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword.passwordConfirmation ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link underline='none' to={`/members`} component={RouterLink}>
                    <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={styles.buttonStyle} onClick={submitData}>{t('button.save')}</Button>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}));

export default Create;