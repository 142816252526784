import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs,Grid, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';
import SponsorGroupFilter from '@layouts/SponsorGroupFilter';

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [moduleStatusList, setModuleStatusList] = useState([]);
    const [bonusList, setBonusList] = useState([]);
    const [bonusListForSelect, setBonusListForSelect] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const [selectedGenealogyType, setSelectedGenealogyType] = useState('individual');
    const [searchValue, setSearchValue] = useState(''); // username

    const checkHandler = (event) => {
        setSearchValue(event.target.value);
    }

    const handleGenealogyTypeChange = (event) => {
        setSelectedGenealogyType(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchValue('');
        setSelectedGenealogyType(selectedGenealogyType);
        callApi();
    };

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData])

    const callApi = () => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
            search: searchValue, // username
            genealogyType: selectedGenealogyType
        }
        getUrl(`/commissions`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setModuleStatusList(response.data.status_list);
                setBonusList(response.data.bonus_list);
            } else {
                addAlert("", response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    };

    useEffect(() => {
        let newStatusList = [];
        _.map(moduleStatusList, (status, key) => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: key.toString() });
        })
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [moduleStatusList, i18n.language]);

    useEffect(() => {
        let newList = [];
        _.map(bonusList, (data, key) => {
            let textDisplay = data.name[i18n.language] ? data.name[i18n.language] : data.name.en;
            newList.push({ text: textDisplay, value: data.id.toString() });
        })
        setBonusListForSelect(newList);
        // eslint-disable-next-line
    }, [bonusList, i18n.language]);

    const columns = useMemo(() => ([
        {
            accessorKey: 'bonus_date',
            header: `${t('commission.bonusDate')}`,
        },
        {
            accessorKey: 'bonus_id',
            header: `${t('commission.bonusName')}`,
            filterFn: 'equals',
            filterSelectOptions: bonusListForSelect,
            filterVariant: 'select',
            Cell: ({ cell }) => (
                <>{cell.row.original.bonus?.name[i18n.language] ? cell.row.original.bonus?.name[i18n.language] : cell.row.original.bonus?.name.en}</>
            ),
            exportKey: `bonus.name.${i18n.language}`
        },
        {
            accessorKey: 'user.username',
            header: `${t('commission.username')}`,
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            accessorFn: (row) => (row.downline_username ? row.downline_username : '-'),
            id: 'downline_username',
            header: `${t('commission.downline')}`,
        },
        /* {
            accessorFn: function (row) {
                if (row?.plan_history_id && row?.plan_name) {
                    let planName = JSON.parse(row.plan_name);
                    return planName[i18n.resolvedLanguage] + ' [ID ' + row.plan_history_id + ']';
                } else {
                    return '';
                }
            },
            id: 'plans_name',
            header: `${t('planHistories.plan')}`,
            enableSorting: false,
            enableColumnFilter: false,
        }, */
        {
            accessorFn: (row) => (row.bv ? row.bv : '-'),
            id: 'bv',
            header: `${t('commission.bv')}`,
        },
        {
            accessorKey: 'percent',
            header: `${t('commission.percent')}`,
        },
        {
            accessorFn: (row) => (row.amount ? row.amount : '-'),
            id: 'amount',
            header: `${t('commission.amount')}`,
        },
        {
            accessorFn: (row) => (row.flushed ? row.flushed : '-'),
            id: 'flushed',
            header: `${t('commission.flushed')}`,
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            accessorKey: 'status',
            header: `${t('commission.status')}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            Cell: ({ cell }) => (
                <>{cell.row.original?.status_display[i18n.language] ? cell.row.original?.status_display[i18n.language] : cell.row.original?.status_display.en}</>
            ),
            exportKey: `status_display.${i18n.language}`,
            maxSize: 20,
        },
        {
            accessorFn: (row) => (row.level ? row.level : '-'),
            id: 'level',
            header: `${t('commission.level')}`,
            maxSize: 20,
        },
        {
            accessorFn: (row) => (row.blevel ? row.blevel : '-'),
            id: 'blevel',
            header: `${t('commission.blevel')}`,
            maxSize: 20,
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
            enableColumnFilter: false,
        },
        {
            accessorKey: 'updated_at',
            header: `${t('general.updatedAt')}`,
            enableColumnFilter: false,
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList, bonusListForSelect]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.commission`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.commission`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <SponsorGroupFilter
                selectedGenealogyType={selectedGenealogyType}
                searchValue={searchValue}
                checkHandler={checkHandler}
                callApi={callApi}
                handleGenealogyTypeChange={handleGenealogyTypeChange}
                handleClearSearch={handleClearSearch}
            />
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'CommissionList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
            />
        </>
    )
}

export default Listing