import _, { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close, Image, TextFields } from '@mui/icons-material';
import { Button, Card, CardActionArea, CardContent, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl } from '@utils/ApiAction';
import { currencyFormat } from '@utils/Tools';
import { useSelector } from 'react-redux';

const NoticeItem = ({ info, dialogInfo, setDialogInfo }) => {
    const { i18n } = useTranslation();
    const theme = useTheme();

    return (
        <div style={{ whiteSpace: 'nowrap', display: 'inline-block', margin: 10 }}>
            <Card style={{ width: 200, backgroundColor: theme.palette.background.main, border: '1px solid #D4A964' }}>
                <CardActionArea onClick={() => setDialogInfo({ ...dialogInfo, open: true, dialogId: info?.id })}>
                    <CardContent>
                        {
                            info?.content_type === "image" ?
                                <Image color="secondary" /> : <TextFields color="secondary" />
                        }
                        <Typography variant="h6" color="primary" noWrap>{info?.title[i18n.language] ? info?.title[i18n.language] : info?.title?.cn}</Typography>
                        <Typography variant="body2">{info?.created_at || '-'}</Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    )
}

const NoticeDialog = ({ dialogInfo, setDialogInfo }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [info, setInfo] = useState({});
    const { addAlert, setLoading, loading } = useNotificationLoading();

    useEffect(() => {
        if (dialogInfo?.dialogId !== null) {
            setLoading(true);

            getUrl(`/notice-info/${dialogInfo?.dialogId}`).then(response => {
                setLoading(false);
                if (response.status) {
                    setInfo(response.data);
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        // eslint-disable-next-line
    }, [dialogInfo?.dialogId]);

    const getLangContent = () => {

        if (info?.content_type === "image") {
            let imageDetail = '/images/login_image.png';
            if (_.size(info?.content_images) > 0) {
                let tempData = _.find(info?.content_images, { 'type': `notice_${i18n.language}` });
                if (!isUndefined(tempData)) {
                    imageDetail = tempData?.file_name;
                } else {
                    let defaultData = _.find(info?.content_images, { 'type': `notice_cn` });
                    if (defaultData) {
                        imageDetail = defaultData?.file_name;
                    }
                }
            }

            return <img src={imageDetail} alt="Notice" style={{ objectFit: 'cover', height: '100%', width: '100%' }} />;

        } else if (info?.content_type === "text") {
            if (info?.content[i18n.language] && info?.content[i18n.language] !== null) {
                return (
                    <div dangerouslySetInnerHTML={{ __html: info?.content[i18n.language] }} />
                )
            } else {
                return (<div dangerouslySetInnerHTML={{ __html: info?.content?.cn }} />
                )
            }

        } else {
            return (
                <div>
                    <Typography variant="h5">{t('general.noData')}</Typography>
                </div>
            )
        }

    }

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={dialogInfo?.open}
            onClose={() => setDialogInfo({ ...dialogInfo, open: false, dialogId: null })}
        >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                <IconButton
                    onClick={() => setDialogInfo({ ...dialogInfo, open: false, dialogId: null })}
                    sx={{
                        ':hover': {
                            backgroundColor: 'transparent',
                        }
                    }}
                >
                    <Close style={{ color: info?.content_type === "image" ? 'white' : theme.palette.primary.main }} fontSize="large" />
                </IconButton>
            </div>
            {
                info?.content_type === "text" &&
                <DialogTitle style={{ backgroundColor: 'transparent' }}>
                    <Typography variant="h6" color="primary" noWrap>{info?.title[i18n.language] ? info?.title[i18n.language] : info?.title?.cn}</Typography>
                </DialogTitle>
            }
            <DialogContent style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'transparent', color: theme.palette.secondary.main }}>
                {
                    loading ?
                        null : (
                            info?.content_type === "image" ?
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', height: '80%' }}>
                                    {getLangContent()}
                                </div>
                                : (
                                    info?.content_type === "text" ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', height: '100%' }}>
                                            {getLangContent()}
                                        </div>
                                        :
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', height: '100%' }}>
                                            <div>
                                                <Typography variant="h5">{t('general.noData')}</Typography>
                                            </div>
                                        </div>
                                )
                        )
                }
            </DialogContent>
        </Dialog>
    )

}

export default function Dashboard() {
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    let navigate = useNavigate();
    const { permissions } = useSelector(state => state.general);
    const classes = useStyles();
    const [dialogInfo, setDialogInfo] = useState({
        open: false,
        dialogId: null,
    })
    const [state, setState] = useState({
        announcements: [],
        news: [],
        newMembers: [],
        newPlanHistories: [],
        newDepositWithdrawal: [],
    });

    useEffect(() => {
        if (_.includes(permissions, `dashboard`)) {
            setLoading(true);
            getUrl('/admins/dashboard-summary').then(response => {
                setLoading(false);
                if (response.status) {
                    setState(prevState => ({
                        ...prevState,
                        announcements: response?.data?.announcements,
                        news: response?.data?.news,
                        newMembers: response?.data?.new_members,
                        newPlanHistories: response?.data?.new_plan_histories,
                        newDepositWithdrawal: response?.data?.new_deposit_withdrawal,
                    }));
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
            // eslint-disable-next-line
        }
    }, [addAlert, permissions, setLoading, t]);

    return (
        <div style={{ minHeight: '100vh' }}>
            <div style={{ paddingBottom: 5 }}>
                <Typography variant="h4" component="h1">{t("title.dashboard")}</Typography>
            </div>
            {_.includes(permissions, `dashboard`) &&
                <>
                    <Grid display="flex" justifyContent="flex-end">
                        <Button size="small" onClick={() => navigate("/monthly-report")}>{t('dashboard.moreReports')}</Button>
                    </Grid>
                    <Grid container spacing={2} display="flex" justifyContent="space-evenly" textAlign="center" paddingTop="10px" paddingBottom="20px">
                        <Grid item xs={12} md={6}>
                            <div style={{ border: '1px solid #CDAC89', borderRadius: 10, boxShadow: '2px 2px 7px 0px #CDAC89' }}>
                                <p>{t('dashboard.newDepositWithdrawDaily')}</p>
                                <hr style={{ marginLeft: 20, marginRight: 20, height: 2, background: '#CDAC89' }} />
                                <Grid container display="flex" justifyContent="space-evenly">
                                    <div>
                                        <p>{t('dashboard.deposit')}</p>
                                        <p style={{ fontSize: 20, fontWeight: 'bold' }}>{currencyFormat(state.newDepositWithdrawal?.['today']?.['deposit'] * 1 || 0, 2)}</p>
                                    </div>
                                    <div>
                                        <p>{t('dashboard.withdrawal')}</p>
                                        <p style={{ fontSize: 20, fontWeight: 'bold' }}>{currencyFormat(state.newDepositWithdrawal?.['today']?.['withdrawal'] * 1 || 0, 2)}</p>
                                    </div>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div style={{ border: '1px solid #CDAC89', borderRadius: 10, boxShadow: '2px 2px 7px 0px #CDAC89' }}>
                                <p>{t('dashboard.newDepositWithdrawWeekly')}</p>
                                <hr style={{ marginLeft: 20, marginRight: 20, height: 2, background: '#CDAC89' }} />
                                <Grid container display="flex" justifyContent="space-evenly">
                                    <div>
                                        <p>{t('dashboard.deposit')}</p>
                                        <p style={{ fontSize: 20, fontWeight: 'bold' }}>{currencyFormat(state.newDepositWithdrawal?.['weekly']?.['deposit'] * 1 || 0, 2)}</p>
                                    </div>
                                    <div>
                                        <p>{t('dashboard.withdrawal')}</p>
                                        <p style={{ fontSize: 20, fontWeight: 'bold' }}>{currencyFormat(state.newDepositWithdrawal?.['weekly']?.['withdrawal'] * 1 || 0, 2)}</p>
                                    </div>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <hr style={{ height: 2, background: '#CDAC89' }} />
                    <Grid container spacing={2} display="flex" justifyContent="space-evenly" textAlign="center" paddingY="20px">
                        <Grid item xs={12} md={3}>
                            <div style={{ border: '1px solid #CDAC89', borderRadius: 10, boxShadow: '2px 2px 7px 0px #CDAC89' }}>
                                <p>{t('dashboard.newMemberDaily')}</p>
                                <p style={{ fontSize: 20, fontWeight: 'bold' }}>{state.newMembers?.['today'] || 0}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div style={{ border: '1px solid #CDAC89', borderRadius: 10, boxShadow: '2px 2px 7px 0px #CDAC89' }}>
                                <p>{t('dashboard.newPlanHistoryDaily')}</p>
                                <p style={{ fontSize: 20, fontWeight: 'bold' }}>{state.newPlanHistories?.['today'] || 0}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div style={{ border: '1px solid #CDAC89', borderRadius: 10, boxShadow: '2px 2px 7px 0px #CDAC89' }}>
                                <p>{t('dashboard.newMemberWeekly')}</p>
                                <p style={{ fontSize: 20, fontWeight: 'bold' }}>{state.newMembers?.['weekly'] || 0}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div style={{ border: '1px solid #CDAC89', borderRadius: 10, boxShadow: '2px 2px 7px 0px #CDAC89' }}>
                                <p>{t('dashboard.newPlanHistoryWeekly')}</p>
                                <p style={{ fontSize: 20, fontWeight: 'bold' }}>{state.newPlanHistories?.['weekly'] || 0}</p>
                            </div>
                        </Grid>
                    </Grid>

                    {/* ANNOUCEMENTS AND NEWS */}
                    {
                        _.size(state?.announcements) > 0 &&
                        <div style={{ gap: 20, display: 'flex', flexDirection: 'column', paddingBottom: 20 }}>
                            <Typography variant="body2">{t('notice.type_announcement')}</Typography>
                            <div className={classes.scrollContainer}>
                                {
                                    _.map(state?.announcements, (data, dataIndex) => {
                                        return (
                                            <NoticeItem info={data} key={dataIndex} dialogInfo={dialogInfo} setDialogInfo={setDialogInfo} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                    {
                        _.size(state?.news) > 0 &&
                        <div style={{ gap: 20, display: 'flex', flexDirection: 'column', paddingBottom: 20 }}>
                            <Typography variant="body2">{t('notice.type_news')}</Typography>
                            <div className={classes.scrollContainer}>
                                {
                                    _.map(state?.news, (data, dataIndex) => {
                                        return (
                                            <NoticeItem info={data} key={dataIndex} dialogInfo={dialogInfo} setDialogInfo={setDialogInfo} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                    <NoticeDialog dialogInfo={dialogInfo} setDialogInfo={setDialogInfo} />
                </>
            }
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    scrollContainer: {
        display: 'flex',
        gap: 10,
        width: '100%',
        overflowX: 'auto',
    }
}))