import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Breadcrumbs, Button, Checkbox, FormControlLabel, Grid, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { getUrl } from '@utils/ApiAction';
import { currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import _ from 'lodash';
import moment from "moment";
import { utils, writeFileXLSX } from "xlsx";

import { MdDownload } from "react-icons/md";

export default function MonthlyReport() {
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const walletTbl = useRef(null);
    const commTbl = useRef(null);
    const planTbl = useRef(null);
    const newMemberTbl = useRef(null);

    const newDate = new Date()
    // const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const [state, setState] = useState({
        date_time: year + '-' + month + '-01',
        yearly: false,
        wallet_transaction_type: [],
        wallet_transactions: [],
        bonus_list: [],
        commissions: [],
        plan_list: [],
        plan_histories: [],
        new_members: [],
    });

    const exportExcel = (tbl, fileName) => {
        let wb = utils.table_to_book(tbl.current);
        writeFileXLSX(wb, fileName + ".xlsx");
    }

    useEffect(() => {
        setLoading(true);
        let apiData = {
            date_time: state.date_time,
            yearly: state.yearly,
        }
        getUrl('/admins/report', apiData).then(response => {
            setLoading(false);
            if (response.status) {
                setState({
                    ...state,
                    wallet_transaction_type: response?.data?.wallet_transactions_type,
                    wallet_transactions: response?.data?.wallet_transactions,
                    bonus_list: response?.data?.bonus_list,
                    commissions: response?.data?.commissions,
                    plan_list: response?.data?.plan_list,
                    plan_histories: response?.data?.plan_histories,
                    new_members: response?.data?.new_members,
                });
            } else {
                addAlert("", response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [state?.yearly, state?.date_time]);

    return (
        <div>
            <div style={{ paddingBottom: 5 }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                        <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t('title.monthlyReports')}</b></Typography>
                    </div>
                    <div>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                                {t('title.dashboard')}
                            </Link>
                            <Typography color="text.primary">{t('title.reports')}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start', padding: 10 }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker label={t('general.dateTime')} views={['month', 'year']} onChange={(newValue) => setState({ ...state, date_time: moment(newValue).format('YYYY-MM-01') })} defaultValue={moment(moment().format("YYYY-MM-01"))} />
                </LocalizationProvider>
                <FormControlLabel
                    style={{ paddingLeft: 10 }}
                    control={<Checkbox checked={state.yearly ? true : false} color="primary" onChange={() => setState({ ...state, yearly: !state.yearly })} />}
                    label={t('general.yearlyReport')}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 20, paddingBottom: 20, alignItems: 'center' }}>
                <Typography style={{ fontSize: 18, marginRight: 15, color: theme.palette.gray.main }}><b>{t('title.walletReports')}</b></Typography>
                <Button variant="outlined" onClick={() => exportExcel(walletTbl, "Wallet_Transactions_Report_" + moment().format('YYYY-MM-DD HH:mm:ss'))}><MdDownload style={{ marginRight: 5 }} />{t('general.export')}</Button>
            </div>
            {
                (_.size(state.wallet_transaction_type) > 0 && _.size(state.wallet_transactions) > 0) ?
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table sx={{ minWidth: 650 }} stickyHeader ref={walletTbl}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('general.dateTime')}</TableCell>
                                    {
                                        _.map(state.wallet_transaction_type, (data, idx) => {
                                            return <TableCell key={`type` + idx} align="right">{data?.details[i18n.resolvedLanguage]}</TableCell>
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(state.wallet_transactions, (row, key) => (
                                    <TableRow
                                        key={'row' + key}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            '&:nth-of-type(odd)': {
                                                backgroundColor: theme.palette.action.hover,
                                            },
                                        }}
                                    >
                                        <TableCell key={key}>{key}</TableCell>
                                        {
                                            _.map(state.wallet_transaction_type, (data, index) => {
                                                let amount = _.find(row, { 'code': data.code })?.total_value * 1;
                                                return <TableCell key={key + `details_` + index} align="right" style={{ color: amount > 0 ? '#009600' : 'black', fontWeight: amount > 0 ? 'bold' : '' }}>{amount > 0 ? currencyFormat(amount, 6) : '-'}</TableCell>
                                            })
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : null
            }
            <hr style={{ marginTop: 30 }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 20, paddingBottom: 20, alignItems: 'center' }}>
                <Typography style={{ fontSize: 18, marginRight: 15, color: theme.palette.gray.main }}><b>{t('title.commissionsReports')}</b></Typography>
                <Button variant="outlined" onClick={() => exportExcel(commTbl, "Commission_Reports_" + moment().format('YYYY-MM-DD HH:mm:ss'))}><MdDownload style={{ marginRight: 5 }} />{t('general.export')}</Button>
            </div>
            {
                (_.size(state.bonus_list) > 0 && _.size(state.commissions) > 0) ?
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table sx={{ minWidth: 650 }} stickyHeader ref={commTbl}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('general.dateTime')}</TableCell>
                                    {
                                        _.map(state.bonus_list, (data, idx) => {
                                            return <TableCell key={`type` + idx} align="right">{data?.name[i18n.resolvedLanguage]}</TableCell>
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(state.commissions, (row, key) => (
                                    <TableRow
                                        key={'row' + key}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            '&:nth-of-type(odd)': {
                                                backgroundColor: theme.palette.action.hover,
                                            },
                                        }}
                                    >
                                        <TableCell key={key}>{key}</TableCell>
                                        {
                                            _.map(state.bonus_list, (data, index) => {
                                                let amount = _.find(row, { 'bonus_type': data.bonus_type })?.total_value * 1;
                                                return <TableCell key={key + `details_` + index} align="right" style={{ color: amount > 0 ? '#009600' : 'black', fontWeight: amount > 0 ? 'bold' : '' }}>{amount > 0 ? currencyFormat(amount, 6) : '-'}</TableCell>
                                            })
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : null
            }
            <hr style={{ marginTop: 30 }} />
            {/* <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 20, paddingBottom: 20, alignItems: 'center' }}>
                <Typography style={{ fontSize: 18, marginRight: 15, color: theme.palette.gray.main }}><b>{t('title.planHistoriesReports')}</b></Typography>
                <Button variant="outlined" onClick={() => exportExcel(planTbl, "Plan_Histories_Reports_" + moment().format('YYYY-MM-DD HH:mm:ss'))}><MdDownload style={{ marginRight: 5 }} />{t('general.export')}</Button>
            </div>
            {
                (_.size(state.plan_list) > 0 && _.size(state.plan_histories) > 0) ?
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table sx={{ minWidth: 650 }} stickyHeader ref={planTbl}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('general.dateTime')}</TableCell>
                                    {
                                        _.map(state.plan_list, (data, idx) => {
                                            return <TableCell key={`type` + idx} align="right">{data?.name[i18n.resolvedLanguage]}</TableCell>
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(state.plan_histories, (row, key) => (
                                    <TableRow
                                        key={'row' + key}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            '&:nth-of-type(odd)': {
                                                backgroundColor: theme.palette.action.hover,
                                            },
                                        }}
                                    >
                                        <TableCell key={key}>{key}</TableCell>
                                        {
                                            _.map(state.plan_list, (data, index) => {
                                                let amount = _.find(row, { 'id': data.id })?.total_value * 1;
                                                return <TableCell key={key + `details_` + index} align="right" style={{ color: amount > 0 ? '#009600' : 'black', fontWeight: amount > 0 ? 'bold' : '' }}>{amount > 0 ? amount : '-'}</TableCell>
                                            })
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : null
            } */}
            <hr style={{ marginTop: 30 }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 20, paddingBottom: 20, alignItems: 'center' }}>
                <Typography style={{ fontSize: 18, marginRight: 15, color: theme.palette.gray.main }}><b>{t('title.newMembersReports')}</b></Typography>
                <Button variant="outlined" onClick={() => exportExcel(newMemberTbl, "New_Members_Reports_" + moment().format('YYYY-MM-DD HH:mm:ss'))}><MdDownload style={{ marginRight: 5 }} />{t('general.export')}</Button>
            </div>
            {
                (_.size(state.new_members) > 0) ?
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table sx={{ minWidth: 650 }} stickyHeader ref={newMemberTbl}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('general.dateTime')}</TableCell>
                                    <TableCell>{t('general.count')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(state.new_members, (data, index) => (
                                    <TableRow
                                        key={'row' + index}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            '&:nth-of-type(odd)': {
                                                backgroundColor: theme.palette.action.hover,
                                            },
                                        }}
                                    >
                                        <TableCell>{data?.month || data?.day}</TableCell>
                                        <TableCell style={{ color: data.total_value > 0 ? '#009600' : 'black', fontWeight: data.total_value > 0 ? 'bold' : '' }}>{data.total_value > 0 ? data.total_value : '-'}</TableCell>
                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : null
            }
        </div>
    );
}