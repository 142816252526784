import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { Box, Breadcrumbs, Button, Chip, FormControlLabel, Checkbox, Divider, Grid, MenuItem, Switch, InputAdornment, Link, TextField, Typography, Dialog, IconButton, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import { Add, Edit, DeleteForever, Close } from '@material-ui/icons';

import { getUrl, postUrl, putUrl } from '@utils/ApiAction';
import { buildFormData } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import BackToPrevious from '@layouts/BackToPrevious';

export default function Detail() {
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const classes = useStyles();
    const theme = useTheme();
    let navigate = useNavigate();
    const location = useLocation();
    const isMountedRef = useRef(null);
    const formType = location.pathname === `/bonus-add` ? "add" : "edit";

    const distributeType = [
        { code: "real", label: t('bonus.distribute.real') },
        { code: "daily", label: t('bonus.distribute.daily') },
        { code: "monthly", label: t('bonus.distribute.monthly') }
    ];

    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        bonus_type: '',
        name: { en: "", cn: "" },
        rank_percent: [],
        apply_for_all: true,
        wallets: [],
        level: '',
        distribute_type: 'daily',
        limit: [],
        description: '',
        extra_setting: '',
        status: true,
    });
    const [dialog, setDialog] = useState({
        open: false,
        type: '',
        data: [],
        label: '',
        id: '',
        percent: '',
        value: '',
        key: '',
        percentNote: ''
    });
    const [fieldLang, setFieldLang] = useState({ title: 'en' });
    const [ranks, setRanks] = useState([]);
    const [wallets, setWallets] = useState([]);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        if (id) {
            getUrl(`/bonuses/${id}`).then(response => {
                const { data } = response;
                if (response.status) {
                    setState({
                        bonus_type: data.bonus_type,
                        name: data.name,
                        rank_percent: data.ranks_display,
                        wallets: data.wallets_display,
                        level: data.level,
                        distribute_type: data.distribute_type,
                        limit: data.limits_display,
                        description: data.description,
                        extra_setting: data?.extra_setting ? data.extra_setting : '',
                        status: data.status,
                    });
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            setState({
                bonus_type: '',
                name: { en: "", cn: "" },
                rank_percent: [],
                apply_for_all: true,
                wallets: [],
                level: '',
                distribute_type: 'daily',
                limit: [],
                description: '',
                extra_setting: '',
                status: true,
            });
        }

        getUrl('/wallets').then(response => {
            if (isMountedRef.current && response.status) {
                setWallets(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        getUrl('/active-ranks').then(response => {
            setLoading(false);
            if (isMountedRef.current && response.status) {
                setRanks(response.data);
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id]);

    const handleChange = ({ target }) => {
        const { name, value } = target;

        if (name === 'status') {
            setState({ ...state, [name]: value, status: target.checked });
        } else if (name === 'applyForAll') {
            setState({ ...state, [name]: value, applyForAll: target.checked });
        } else {
            setState({ ...state, [name]: value });
        }
    }

    const handleDialogOpen = (type, key, id, percent) => {
        let data = [];
        let label = '';
        let percentNote = '';
        if (type === 'ranks') {
            data = ranks;
            label = t('bonus.rankPercent');
            // percentNote = t('bonus.percentNote');
        } else if (type === 'wallets') {
            data = wallets;
            label = t('bonus.wallets');
        } else if (type === 'limits') {
            data = ranks;
            label = t('bonus.limit');
        }
        setDialog({
            open: true,
            type: type,
            data: data,
            label: label,
            id: id,
            percent: type !== 'limits' ? percent : '',
            value: type === 'limits' ? percent : '',
            key: key,
            percentNote: percentNote
        });
    }

    const handleDialogClose = () => {
        setDialog({
            open: false,
            type: '',
            data: [],
            label: '',
            id: '',
            percent: '',
            value: '',
            key: '',
            percentNote: ''
        });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors([]);
        let postData = {
            ...state,
            apply_for_all: state.apply_for_all ? 1 : 0,
            status: state.status ? 1 : 0,
            _method: formType === 'edit' ? 'put' : 'post'
        };

        if (formType === 'edit' && id) {
            postUrl(`/bonuses/${id}`, postData).then(response => {
                setLoading(false);
                const { status, errors } = response;
                if (status) {
                    addAlert('', t('success.editSuccess'), 'success', '');
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.editError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            postUrl(`/bonuses`, postData).then(response => {
                setLoading(false);
                const { status, data, errors } = response;
                if (status) {
                    addAlert('', t('success.createSuccess'), 'success', '');
                    navigate(`/bonus-edit/${data.id}`, { replace: true });
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.createError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    const dialogAddEdit = () => {
        let tempData = [];
        let repeat = false;
        if (dialog.type === 'ranks') {
            tempData = state.rank_percent;
        } else if (dialog.type === 'wallets') {
            tempData = state.wallets;
        } else if (dialog.type === 'limits') {
            tempData = state.limit;
        }

        if (isNaN(dialog.key)) { // add
            _.map(tempData, (data, key) => {
                if (parseInt(data.id) === parseInt(dialog.id)) {
                    repeat = true;
                }
            });
            if (repeat) {
                addAlert('', t('bonus.duplicatedID'), 'error', '');
            } else {
                if (dialog.id !== 'select') {
                    if (dialog.type === 'limits') {
                        tempData = tempData.concat({ id: dialog.id, value: dialog.value });
                    } else {
                        tempData = tempData.concat({ id: dialog.id, percent: dialog.percent });
                    }
                    handleDialogClose();
                    if (dialog.type === 'ranks') {
                        setState({ ...state, rank_percent: tempData });
                    } else if (dialog.type === 'wallets') {
                        setState({ ...state, wallets: tempData });
                    } else if (dialog.type === 'limits') {
                        setState({ ...state, limit: tempData });
                    }
                } else {
                    addAlert('', t('bonus.fillAllColumn'), 'error', '');
                }
            }
        } else { // edit
            _.map(tempData, (data, key) => {
                if (dialog.key !== key) {
                    if (parseInt(data.id) === parseInt(dialog.id)) {
                        repeat = true;
                    }
                }
            });
            if (repeat) {
                addAlert('', t('bonus.duplicatedID'), 'error', '');
            } else {
                _.map(tempData, (data, key) => {
                    if (dialog.key === key) {
                        tempData[key]['id'] = dialog.id;
                        if (dialog.type !== 'limits') {
                            tempData[key]['percent'] = dialog.percent;
                        } else {
                            tempData[key]['value'] = dialog.percent;
                        }
                    }
                });
                handleDialogClose();
                if (dialog.type === 'ranks') {
                    setState({ ...state, rank_percent: tempData });
                } else if (dialog.type === 'wallets') {
                    setState({ ...state, wallets: tempData });
                } else if (dialog.type === 'limits') {
                    setState({ ...state, limit: tempData });
                }
            }
        }
    }

    const dialogDelete = (type, id) => {
        if (type === 'ranks') {
            let tempData = state.rank_percent;
            if (id === 'default' || id === 'all') {
                tempData = tempData.filter(item => item.id !== id);
            } else {
                tempData = tempData.filter(item => parseInt(item.id) !== parseInt(id));
            }
            setState({ ...state, rank_percent: tempData });
        } else if (type === 'wallets') {
            let tempData = state.wallets;
            tempData = tempData.filter(item => parseInt(item.id) !== parseInt(id));
            setState({ ...state, wallets: tempData });
        } else if (type === 'limits') {
            let tempData = state.limit;
            if (id === 'default' || id === 'all') {
                tempData = tempData.filter(item => item.id !== id);
            } else {
                tempData = tempData.filter(item => parseInt(item.id) !== parseInt(id));
            }
            setState({ ...state, limit: tempData });
        }
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.bonus${formType === 'edit' ? 'Edit' : 'Add'}`)}</b></Typography>
                <div style={{ paddingBottom: 15 }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/bonus`}>
                            {t(`title.bonus`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.bonus${formType === 'edit' ? 'Edit' : 'Add'}`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '2%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`bonus.bonusType`)}
                            variant="outlined"
                            value={state?.bonus_type}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.bonus_type ? inputErrors.bonus_type : ''}
                            error={inputErrors && inputErrors.bonus_type ? true : false}
                            name="bonus_type"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('bonus.name')}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    {
                                        _.map(['en', 'cn'], (value) => (
                                            <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, title: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.title === value ? theme.palette.primary.main : '#efefef', color: fieldLang.title === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                        ))
                                    }
                                </InputAdornment>,
                            }}
                            onChange={({ target }) => setState({ ...state, name: { ...state.name, [fieldLang.title]: target.value } })}
                            value={state?.name && state?.name[fieldLang.title] ? state?.name[fieldLang.title] : ""}
                            helperText={inputErrors && (inputErrors['name'] || inputErrors['name.en'] || inputErrors['name.cn'])}
                            error={inputErrors && (inputErrors['name'] || inputErrors['name.en'] || inputErrors['name.cn']) ? true : false}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            name="level"
                            label={t('bonus.level')}
                            variant="outlined"
                            fullWidth
                            value={state?.level}
                            helperText={inputErrors && inputErrors.level ? inputErrors.level : ''}
                            error={inputErrors && inputErrors.level ? true : false}
                            onChange={handleChange}
                        />
                        <Typography variant="caption" style={{ color: "#808080" }}>{t('bonus.levelNote')}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            select
                            label={t('bonus.distributeType')}
                            name="distribute_type"
                            value={state?.distribute_type}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            helperText={inputErrors && inputErrors.distribute_type ? inputErrors.distribute_type : ''}
                            error={inputErrors && inputErrors.distribute_type ? true : false}
                        >
                            {distributeType.map((option) => {
                                return (
                                    <MenuItem key={option.code} value={option.code}>
                                        {option.label}
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            name="description"
                            label={t('bonus.description')}
                            variant="outlined"
                            fullWidth
                            value={state?.description}
                            helperText={inputErrors && inputErrors.description ? inputErrors.description : ''}
                            error={inputErrors && inputErrors.description ? true : false}
                            onChange={handleChange}
                            multiline
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            name="extraSetting"
                            label={t('bonus.extraSetting')}
                            variant="outlined"
                            fullWidth
                            value={typeof state.extra_setting === 'object' ? JSON.stringify(state.extra_setting) : state.extra_setting}
                            helperText={inputErrors && inputErrors.extra_setting ? inputErrors.extra_setting : ''}
                            error={inputErrors && inputErrors.extra_setting ? true : false}
                            onChange={({ target }) => {
                                let newValue = target.value;
                                try {
                                    // Attempt to parse the value as JSON
                                    const parsedValue = JSON.parse(target.value);
                                    // Update the value only if parsing succeeds
                                    if (typeof parsedValue === 'object' && parsedValue !== null) {
                                        newValue = parsedValue;
                                    }
                                } catch (error) {
                                    // Parsing failed, keep the original value
                                }
                                setState({ ...state, extra_setting: newValue });
                            }}
                            multiline
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%', justifyContent: 'flex-end' }, }} >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={state?.status ? true : false}
                                    onChange={handleChange}
                                    name="status"
                                    color="primary"
                                />
                            }
                            labelPlacement="start"
                            label={t('bonus.status.title')}
                        />
                    </Box>
                </Grid>
                {/* <Grid item xs={12}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state?.apply_for_all}
                                    onChange={handleChange}
                                    color="primary"
                                    name="apply_for_all"
                                />
                            }
                            label={t('bonus.applyForAll')}
                        />
                    </Box>
                </Grid> */}
            </Grid>

            <Divider />

            <Grid container spacing={3} style={{ padding: '2% 0' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography>{t('bonus.rankPercent')}</Typography>
                        {
                            _.map(state?.rank_percent, (data, key) => {
                                let rank_name = '';
                                if (ranks) {
                                    _.map(ranks, rank => {
                                        if (rank.id === parseInt(data.id)) {
                                            rank_name = rank.name;
                                        } 
                                    })
                                }
                                return (
                                    <Box key={key}>
                                        <IconButton color="primary" onClick={() => handleDialogOpen('ranks', key, data.id, data.percent)}><Edit /></IconButton>
                                        <IconButton color="primary" onClick={() => dialogDelete('ranks', data.id)}><DeleteForever /></IconButton>
                                        {

                                            (
                                                _.size(rank_name) > 0
                                                    ? (rank_name ? rank_name?.[i18n.language] : rank_name?.cn)
                                                    : t(`bonus.${data.id}`)
                                            ) + ': ' + data.percent + '%'
                                        }
                                    </Box>
                                )
                            })
                        }
                        <Box>
                            <IconButton color="primary" onClick={() => handleDialogOpen('ranks', 'none', 'select', '')}><Add /></IconButton>
                        </Box>
                        {
                            inputErrors && inputErrors.rank_percent ?
                                <Typography variant="body2" style={{ color: "red" }}>{inputErrors.rank_percent}</Typography>
                                : null
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography>{t('bonus.wallets')}</Typography>
                        {
                            _.map(state?.wallets, (data, key) => {
                                let wallet_name = '';
                                if (wallets) {
                                    _.map(wallets, wallet => {
                                        if (wallet.id === parseInt(data.id)) {
                                            wallet_name = wallet.name;
                                        }
                                    })
                                }
                                return (
                                    <Box key={key}>
                                        <IconButton color="primary" onClick={() => handleDialogOpen('wallets', key, data.id, data.percent)}><Edit /></IconButton>
                                        <IconButton color="primary" onClick={() => dialogDelete('wallets', data.id)}><DeleteForever /></IconButton>
                                        {(wallet_name ? wallet_name?.[i18n.language] : wallet_name?.cn) + ': ' + data.percent + '%'}
                                    </Box>
                                )
                            })
                        }
                        <Box>
                            <IconButton color="primary" onClick={() => handleDialogOpen('wallets', 'none', 'select', '')}><Add /></IconButton>
                        </Box>
                        {
                            inputErrors && inputErrors.wallets ?
                                <Typography variant="body2" style={{ color: "red" }}>{inputErrors.wallets}</Typography>
                                : null
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography>{t('bonus.limit')}</Typography>
                        {
                            _.map(state?.limit, (data, key) => {
                                let rank_name = '';
                                if (ranks) {
                                    _.map(ranks, rank => {
                                        if (rank.id === parseInt(data.id)) {
                                            rank_name = rank.name;
                                        }
                                    })
                                }
                                return (
                                    <Box key={key}>
                                        <IconButton color="primary" onClick={() => handleDialogOpen('limits', key, data.id, data.value)}><Edit /></IconButton>
                                        <IconButton color="primary" onClick={() => dialogDelete('limits', data.id)}><DeleteForever /></IconButton>
                                        {

                                            (
                                                _.size(rank_name) > 0
                                                    ? (rank_name ? rank_name?.[i18n.language] : rank_name?.cn)
                                                    : t(`bonus.${data.id}`)
                                            ) + ': ' + data.value
                                        }
                                    </Box>
                                )
                            })
                        }
                        <Box>
                            <IconButton color="primary" onClick={() => handleDialogOpen('limits', 'none', 'select', '')}><Add /></IconButton>
                        </Box>
                        {
                            inputErrors && inputErrors.limit ?
                                <Typography variant="body2" style={{ color: "red" }}>{inputErrors.limit}</Typography>
                                : null
                        }
                    </Box>
                </Grid>
            </Grid>

            <Dialog onClose={handleDialogClose} open={dialog.open} fullWidth>
                <DialogTitle onClose={handleDialogClose}>
                    {dialog.label}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <TextField
                                label={dialog.label}
                                variant="outlined"
                                fullWidth
                                select
                                value={dialog?.id}
                                onChange={({ target }) => setDialog({ ...dialog, id: target.value })}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                                <option key='' value='select' disabled>
                                    {t('general.pleaseSelect')}
                                </option>
                                {
                                    dialog.type !== 'wallets' &&
                                    <option key='default' value={"default"}>{t('bonus.default')}</option>
                                }
                                {
                                    dialog.data.map((value, key) => (
                                        <option key={key} value={value.id}>
                                            {value.name ? value.name[i18n.language] : value.name.cn}
                                        </option>
                                    ))
                                }
                                {
                                    dialog.type !== 'wallets' &&
                                    <option key='all' value="all">{t('bonus.all')}</option>
                                }
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField
                                label={dialog.type === 'limits' ? t('bonus.amount') : t('bonus.percent')}
                                variant="outlined"
                                fullWidth
                                value={dialog.type === 'limits' ? dialog.value : dialog?.percent}
                                onChange={({ target }) => {
                                    if (dialog.type === 'limits') { setDialog({ ...dialog, value: target.value }) }
                                    else { setDialog({ ...dialog, percent: target.value }) }
                                }}
                            />
                            <Typography variant="body2" style={{ color: "#808080" }}>{dialog.percentNote}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant="contained" onClick={dialogAddEdit} color="primary">
                        {t('button.save')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/bonus`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const CustomDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <DialogTitle disableTypography className={classes.root} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <Close />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});

const CustomDialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(DialogContent);

const CustomDialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(DialogActions);