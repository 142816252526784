import React from 'react';
import { useTranslation } from 'react-i18next';

import { Close } from '@mui/icons-material';
import { Button, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Radio, RadioGroup, TextField } from '@mui/material';

import { MdSearch } from "react-icons/md";

const SponsorGroupFilter = ({ selectedGenealogyType, searchValue, checkHandler, callApi, handleGenealogyTypeChange, handleClearSearch }) => {
    const { t } = useTranslation();

    return (
        <Grid container alignItems="center" spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12} sm={4}>
                <TextField
                    label={t('user.username')}
                    variant="outlined"
                    fullWidth
                    value={searchValue}
                    onChange={checkHandler}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchValue && (
                                    <IconButton onClick={handleClearSearch} size="large">
                                        <Close />
                                    </IconButton>
                                )}
                                <Button variant="contained" onClick={callApi}> <MdSearch size={20} /></Button>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={8}>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="searchBy"
                        name="searchBy"
                        value={selectedGenealogyType}
                        onChange={handleGenealogyTypeChange}
                        row
                    >
                        <FormControlLabel value="individual" control={<Radio />} label={t('genealogies.individual')} />
                        <FormControlLabel value="directSponsor" control={<Radio />} label={t(`genealogies.directSponsor`)} />
                        <FormControlLabel value="sponsor" control={<Radio />} label={t('genealogies.sponsor')} />
                        {/* <FormControlLabel value="placement" control={<Radio />} label={t('genealogies.placement')} />
                    <FormControlLabel value="both" control={<Radio />} label={t('genealogies.sponsorPlacement')} /> */}
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default SponsorGroupFilter;