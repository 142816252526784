import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { putUrl, getUrl } from '@utils/ApiAction';
import { Link, TextField, Box, Button, Grid, InputAdornment, IconButton } from '@mui/material';

import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

const MODULE = 'user';
const INITIAL_STATE = {
    securityPassword: '',
    securityPasswordConfirmation: '',
}

const SecurityPasswordInfo = () => {
    let { id } = useParams();
    const theme = useTheme();
    const styles = useStyles();
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState({
        securityPassword: false,
        securityPasswordConfirmation: false,
    });
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const { addAlert, setLoading } = useNotificationLoading();

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const handleChange = ({ target }) => {
        let { name, value } = target;
        setState({ ...state, [name]: value });
    };

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            security_password: state.securityPassword,
            security_password_confirmation: state.securityPasswordConfirmation,
        }
        putUrl(`/members/${id}/security-password`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState(INITIAL_STATE);
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response?.message ||  t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <TextField
                            name="securityPassword"
                            label={t(`${MODULE}.securityPassword`)}
                            type={showPassword.securityPassword ? 'text' : 'password'}
                            value={state.securityPassword}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            helperText={inputErrors && inputErrors.securityPassword ? inputErrors.securityPassword : ''}
                            error={inputErrors && inputErrors.securityPassword ? true : false}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleClickShowPassword('securityPassword')}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword.securityPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <TextField
                            name="securityPasswordConfirmation"
                            label={t(`${MODULE}.confirmSecurityPassword`)}
                            type={showPassword.securityPasswordConfirmation ? 'text' : 'password'}
                            value={state.securityPasswordConfirmation}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                            error={inputErrors && inputErrors.password_confirmation ? true : false}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleClickShowPassword('securityPasswordConfirmation')}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword.securityPasswordConfirmation ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link underline='none' to={`/admins`} component={RouterLink}>
                    <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={styles.buttonStyle} onClick={submitData}>{t('button.edit')}</Button>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}));

export default SecurityPasswordInfo;