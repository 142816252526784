const COUNTRIES = [
    { 'code': 'afghanistan', 'value': 'afghanistan', label: 'Afghanistan' },
    { 'code': 'albania', 'value': 'albania', label: 'Albania' },
    { 'code': 'algeria', 'value': 'algeria', label: 'Algeria' },
    { 'code': 'andorra', 'value': 'andorra', label: 'Andorra' },
    { 'code': 'angola', 'value': 'angola', label: 'Angola' },
    { 'code': 'antigua_and_barbuda', 'value': 'antigua_and_barbuda', label: 'Antigua and Barbuda' },
    { 'code': 'argentina', 'value': 'argentina', label: 'Argentina' },
    { 'code': 'armenia', 'value': 'armenia', label: 'Armenia' },
    { 'code': 'australia', 'value': 'australia', label: 'Australia' },
    { 'code': 'austria', 'value': 'austria', label: 'Austria' },
    { 'code': 'azerbaijan', 'value': 'azerbaijan', label: 'Azerbaijan' },
    { 'code': 'bahamas', 'value': 'bahamas', label: 'Bahamas' },
    { 'code': 'bahrain', 'value': 'bahrain', label: 'Bahrain' },
    { 'code': 'bangladesh', 'value': 'bangladesh', label: 'Bangladesh' },
    { 'code': 'barbados', 'value': 'barbados', label: 'Barbados' },
    { 'code': 'belarus', 'value': 'belarus', label: 'Belarus' },
    { 'code': 'belgium', 'value': 'belgium', label: 'Belgium' },
    { 'code': 'belize', 'value': 'belize', label: 'Belize' },
    { 'code': 'benin', 'value': 'benin', label: 'Benin' },
    { 'code': 'bhutan', 'value': 'bhutan', label: 'Bhutan' },
    { 'code': 'bolivia', 'value': 'bolivia', label: 'Bolivia' },
    { 'code': 'bosnia_and_herzegovina', 'value': 'bosnia_and_herzegovina', label: 'Bosnia and Herzegovina' },
    { 'code': 'botswana', 'value': 'botswana', label: 'Botswana' },
    { 'code': 'brazil', 'value': 'brazil', label: 'Brazil' },
    { 'code': 'brunei', 'value': 'brunei', label: 'Brunei' },
    { 'code': 'bulgaria', 'value': 'bulgaria', label: 'Bulgaria' },
    { 'code': 'burkina_faso', 'value': 'burkina_faso', label: 'Burkina Faso' },
    { 'code': 'burundi', 'value': 'burundi', label: 'Burundi' },
    { 'code': 'cabo_verde', 'value': 'cabo_verde', label: 'Cabo Verde' },
    { 'code': 'cambodia', 'value': 'cambodia', label: 'Cambodia' },
    { 'code': 'cameroon', 'value': 'cameroon', label: 'Cameroon' },
    { 'code': 'canada', 'value': 'canada', label: 'Canada' },
    { 'code': 'central_african_republic', 'value': 'central_african_republic', label: 'Central African Republic' },
    { 'code': 'chad', 'value': 'chad', label: 'Chad' },
    { 'code': 'chile', 'value': 'chile', label: 'Chile' },
    { 'code': 'china', 'value': 'china', label: 'China' },
    { 'code': 'colombia', 'value': 'colombia', label: 'Colombia' },
    { 'code': 'comoros', 'value': 'comoros', label: 'Comoros' },
    { 'code': 'congo', 'value': 'congo', label: 'Congo' },
    { 'code': 'costa_rica', 'value': 'costa_rica', label: 'Costa Rica' },
    { 'code': 'croatia', 'value': 'croatia', label: 'Croatia' },
    { 'code': 'cuba', 'value': 'cuba', label: 'Cuba' },
    { 'code': 'cyprus', 'value': 'cyprus', label: 'Cyprus' },
    { 'code': 'czech_republic', 'value': 'czech_republic', label: 'Czech Republic' },
    { 'code': 'denmark', 'value': 'denmark', label: 'Denmark' },
    { 'code': 'djibouti', 'value': 'djibouti', label: 'Djibouti' },
    { 'code': 'dominica', 'value': 'dominica', label: 'Dominica' },
    { 'code': 'dominican_republic', 'value': 'dominican_republic', label: 'Dominican Republic' },
    { 'code': 'ecuador', 'value': 'ecuador', label: 'Ecuador' },
    { 'code': 'egypt', 'value': 'egypt', label: 'Egypt' },
    { 'code': 'el_salvador', 'value': 'el_salvador', label: 'El Salvador' },
    { 'code': 'equatorial_guinea', 'value': 'equatorial_guinea', label: 'Equatorial Guinea' },
    { 'code': 'eritrea', 'value': 'eritrea', label: 'Eritrea' },
    { 'code': 'estonia', 'value': 'estonia', label: 'Estonia' },
    { 'code': 'eswatini', 'value': 'eswatini', label: 'Eswatini' },
    { 'code': 'ethiopia', 'value': 'ethiopia', label: 'Ethiopia' },
    { 'code': 'fiji', 'value': 'fiji', label: 'Fiji' },
    { 'code': 'finland', 'value': 'finland', label: 'Finland' },
    { 'code': 'france', 'value': 'france', label: 'France' },
    { 'code': 'gabon', 'value': 'gabon', label: 'Gabon' },
    { 'code': 'gambia', 'value': 'gambia', label: 'Gambia' },
    { 'code': 'georgia', 'value': 'georgia', label: 'Georgia' },
    { 'code': 'germany', 'value': 'germany', label: 'Germany' },
    { 'code': 'ghana', 'value': 'ghana', label: 'Ghana' },
    { 'code': 'greece', 'value': 'greece', label: 'Greece' },
    { 'code': 'grenada', 'value': 'grenada', label: 'Grenada' },
    { 'code': 'guatemala', 'value': 'guatemala', label: 'Guatemala' },
    { 'code': 'guinea', 'value': 'guinea', label: 'Guinea' },
    { 'code': 'guinea_bissau', 'value': 'guinea_bissau', label: 'Guinea-Bissau' },
    { 'code': 'guyana', 'value': 'guyana', label: 'Guyana' },
    { 'code': 'haiti', 'value': 'haiti', label: 'Haiti' },
    { 'code': 'honduras', 'value': 'honduras', label: 'Honduras' },
    { 'code': 'hungary', 'value': 'hungary', label: 'Hungary' },
    { 'code': 'iceland', 'value': 'iceland', label: 'Iceland' },
    { 'code': 'india', 'value': 'india', label: 'India' },
    { 'code': 'indonesia', 'value': 'indonesia', label: 'Indonesia' },
    { 'code': 'iran', 'value': 'iran', label: 'Iran' },
    { 'code': 'iraq', 'value': 'iraq', label: 'Iraq' },
    { 'code': 'ireland', 'value': 'ireland', label: 'Ireland' },
    { 'code': 'israel', 'value': 'israel', label: 'Israel' },
    { 'code': 'italy', 'value': 'italy', label: 'Italy' },
    { 'code': 'jamaica', 'value': 'jamaica', label: 'Jamaica' },
    { 'code': 'japan', 'value': 'japan', label: 'Japan' },
    { 'code': 'jordan', 'value': 'jordan', label: 'Jordan' },
    { 'code': 'kazakhstan', 'value': 'kazakhstan', label: 'Kazakhstan' },
    { 'code': 'kenya', 'value': 'kenya', label: 'Kenya' },
    { 'code': 'kiribati', 'value': 'kiribati', label: 'Kiribati' },
    { 'code': 'kuwait', 'value': 'kuwait', label: 'Kuwait' },
    { 'code': 'kyrgyzstan', 'value': 'kyrgyzstan', label: 'Kyrgyzstan' },
    { 'code': 'laos', 'value': 'laos', label: 'Laos' },
    { 'code': 'latvia', 'value': 'latvia', label: 'Latvia' },
    { 'code': 'lebanon', 'value': 'lebanon', label: 'Lebanon' },
    { 'code': 'lesotho', 'value': 'lesotho', label: 'Lesotho' },
    { 'code': 'liberia', 'value': 'liberia', label: 'Liberia' },
    { 'code': 'libya', 'value': 'libya', label: 'Libya' },
    { 'code': 'liechtenstein', 'value': 'liechtenstein', label: 'Liechtenstein' },
    { 'code': 'lithuania', 'value': 'lithuania', label: 'Lithuania' },
    { 'code': 'luxembourg', 'value': 'luxembourg', label: 'Luxembourg' },
    { 'code': 'madagascar', 'value': 'madagascar', label: 'Madagascar' },
    { 'code': 'malawi', 'value': 'malawi', label: 'Malawi' },
    { 'code': 'malaysia', 'value': 'malaysia', label: 'Malaysia' },
    { 'code': 'maldives', 'value': 'maldives', label: 'Maldives' },
    { 'code': 'mali', 'value': 'mali', label: 'Mali' },
    { 'code': 'malta', 'value': 'malta', label: 'Malta' },
    { 'code': 'marshall_islands', 'value': 'marshall_islands', label: 'Marshall Islands' },
    { 'code': 'mauritania', 'value': 'mauritania', label: 'Mauritania' },
    { 'code': 'mauritius', 'value': 'mauritius', label: 'Mauritius' },
    { 'code': 'mexico', 'value': 'mexico', label: 'Mexico' },
    { 'code': 'micronesia', 'value': 'micronesia', label: 'Micronesia' },
    { 'code': 'moldova', 'value': 'moldova', label: 'Moldova' },
    { 'code': 'monaco', 'value': 'monaco', label: 'Monaco' },
    { 'code': 'mongolia', 'value': 'mongolia', label: 'Mongolia' },
    { 'code': 'montenegro', 'value': 'montenegro', label: 'Montenegro' },
    { 'code': 'morocco', 'value': 'morocco', label: 'Morocco' },
    { 'code': 'mozambique', 'value': 'mozambique', label: 'Mozambique' },
    { 'code': 'myanmar', 'value': 'myanmar', label: 'Myanmar' },
    { 'code': 'namibia', 'value': 'namibia', label: 'Namibia' },
    { 'code': 'nauru', 'value': 'nauru', label: 'Nauru' },
    { 'code': 'nepal', 'value': 'nepal', label: 'Nepal' },
    { 'code': 'netherlands', 'value': 'netherlands', label: 'Netherlands' },
    { 'code': 'new_zealand', 'value': 'new_zealand', label: 'New Zealand' },
    { 'code': 'nicaragua', 'value': 'nicaragua', label: 'Nicaragua' },
    { 'code': 'niger', 'value': 'niger', label: 'Niger' },
    { 'code': 'nigeria', 'value': 'nigeria', label: 'Nigeria' },
    { 'code': 'north_macedonia', 'value': 'north_macedonia', label: 'North Macedonia' },
    { 'code': 'norway', 'value': 'norway', label: 'Norway' },
    { 'code': 'oman', 'value': 'oman', label: 'Oman' },
    { 'code': 'pakistan', 'value': 'pakistan', label: 'Pakistan' },
    { 'code': 'palau', 'value': 'palau', label: 'Palau' },
    { 'code': 'panama', 'value': 'panama', label: 'Panama' },
    { 'code': 'papua_new_guinea', 'value': 'papua_new_guinea', label: 'Papua New Guinea' },
    { 'code': 'paraguay', 'value': 'paraguay', label: 'Paraguay' },
    { 'code': 'peru', 'value': 'peru', label: 'Peru' },
    { 'code': 'philippines', 'value': 'philippines', label: 'Philippines' },
    { 'code': 'poland', 'value': 'poland', label: 'Poland' },
    { 'code': 'portugal', 'value': 'portugal', label: 'Portugal' },
    { 'code': 'qatar', 'value': 'qatar', label: 'Qatar' },
    { 'code': 'romania', 'value': 'romania', label: 'Romania' },
    { 'code': 'russia', 'value': 'russia', label: 'Russia' },
    { 'code': 'rwanda', 'value': 'rwanda', label: 'Rwanda' },
    { 'code': 'saint_kitts_and_nevis', 'value': 'saint_kitts_and_nevis', label: 'Saint Kitts and Nevis' },
    { 'code': 'saint_lucia', 'value': 'saint_lucia', label: 'Saint Lucia' },
    { 'code': 'saint_vincent_and_the_grenadines', 'value': 'saint_vincent_and_the_grenadines', label: 'Saint Vincent and the Grenadines' },
    { 'code': 'samoa', 'value': 'samoa', label: 'Samoa' },
    { 'code': 'san_marino', 'value': 'san_marino', label: 'San Marino' },
    { 'code': 'sao_tome_and_principe', 'value': 'sao_tome_and_principe', label: 'Sao Tome and Principe' },
    { 'code': 'saudi_arabia', 'value': 'saudi_arabia', label: 'Saudi Arabia' },
    { 'code': 'senegal', 'value': 'senegal', label: 'Senegal' },
    { 'code': 'serbia', 'value': 'serbia', label: 'Serbia' },
    { 'code': 'seychelles', 'value': 'seychelles', label: 'Seychelles' },
    { 'code': 'sierra_leone', 'value': 'sierra_leone', label: 'Sierra Leone' },
    { 'code': 'singapore', 'value': 'singapore', label: 'Singapore' },
    { 'code': 'slovakia', 'value': 'slovakia', label: 'Slovakia' },
    { 'code': 'slovenia', 'value': 'slovenia', label: 'Slovenia' },
    { 'code': 'solomon_islands', 'value': 'solomon_islands', label: 'Solomon Islands' },
    { 'code': 'somalia', 'value': 'somalia', label: 'Somalia' },
    { 'code': 'south_africa', 'value': 'south_africa', label: 'South Africa' },
    { 'code': 'south_sudan', 'value': 'south_sudan', label: 'South Sudan' },
    { 'code': 'spain', 'value': 'spain', label: 'Spain' },
    { 'code': 'sri_lanka', 'value': 'sri_lanka', label: 'Sri Lanka' },
    { 'code': 'sudan', 'value': 'sudan', label: 'Sudan' },
    { 'code': 'suriname', 'value': 'suriname', label: 'Suriname' },
    { 'code': 'sweden', 'value': 'sweden', label: 'Sweden' },
    { 'code': 'switzerland', 'value': 'switzerland', label: 'Switzerland' },
    { 'code': 'syria', 'value': 'syria', label: 'Syria' },
    { 'code': 'taiwan', 'value': 'taiwan', label: 'Taiwan' },
    { 'code': 'tajikistan', 'value': 'tajikistan', label: 'Tajikistan' },
    { 'code': 'tanzania', 'value': 'tanzania', label: 'Tanzania' },
    { 'code': 'thailand', 'value': 'thailand', label: 'Thailand' },
    { 'code': 'togo', 'value': 'togo', label: 'Togo' },
    { 'code': 'tonga', 'value': 'tonga', label: 'Tonga' },
    { 'code': 'trinidad_and_tobago', 'value': 'trinidad_and_tobago', label: 'Trinidad and Tobago' },
    { 'code': 'tunisia', 'value': 'tunisia', label: 'Tunisia' },
    { 'code': 'turkey', 'value': 'turkey', label: 'Turkey' },
    { 'code': 'turkmenistan', 'value': 'turkmenistan', label: 'Turkmenistan' },
    { 'code': 'tuvalu', 'value': 'tuvalu', label: 'Tuvalu' },
    { 'code': 'uganda', 'value': 'uganda', label: 'Uganda' },
    { 'code': 'ukraine', 'value': 'ukraine', label: 'Ukraine' },
    { 'code': 'united_arab_emirates', 'value': 'united_arab_emirates', label: 'United Arab Emirates' },
    { 'code': 'united_kingdom', 'value': 'united_kingdom', label: 'United Kingdom' },
    { 'code': 'united_states', 'value': 'united_states', label: 'United States' },
    { 'code': 'uruguay', 'value': 'uruguay', label: 'Uruguay' },
    { 'code': 'uzbekistan', 'value': 'uzbekistan', label: 'Uzbekistan' },
    { 'code': 'vanuatu', 'value': 'vanuatu', label: 'Vanuatu' },
    { 'code': 'vatican_city', 'value': 'vatican_city', label: 'Vatican City' },
    { 'code': 'venezuela', 'value': 'venezuela', label: 'Venezuela' },
    { 'code': 'vietnam', 'value': 'vietnam', label: 'Vietnam' },
    { 'code': 'yemen', 'value': 'yemen', label: 'Yemen' },
    { 'code': 'zambia', 'value': 'zambia', label: 'Zambia' },
    { 'code': 'zimbabwe', 'value': 'zimbabwe', label: 'Zimbabwe' }
];
export default COUNTRIES;