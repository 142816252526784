import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { Box, Breadcrumbs, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { postUrl, getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const WalletAdjustment = () => {
    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        username: '',
        wallet_id: '',
        wallet_action: '',
        amount: '',
        remark: ''
    });
    const action = [{ code: "add", label: t(`wallets.add`) }, { code: "deduct", label: t(`wallets.deduct`) }];
    const [walletList, setWalletList] = useState([]);
    const isMountedRef = useRef(null);
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`/wallets`).then(result => {
            if (isMountedRef.current && result.status) {
                setWalletList(result.data);
            }
        }).catch((error) => {
            addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let updateData = {
            username: state.username,
            wallet_id: state.wallet_id,
            wallet_action: state.wallet_action,
            amount: state.amount,
            remark: state.remark
        }
        postUrl(`wallets/admin-adjustment`, updateData).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                setState({
                    username: '',
                    wallet_id: '',
                    wallet_action: '',
                    amount: '',
                    remark: ''
                });
                addAlert('', t('success.createSuccess'), 'success', '');
            } else {
                setInputErrors(errors);
                addAlert('', t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.walletAdjustment`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/wallets`}>
                            {t(`title.wallets`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.walletAdjustment`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.username`)}
                            variant="outlined"
                            value={state.username}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            name="username"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.name`)}
                            variant="outlined"
                            value={state.wallet_id}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.wallet_id ? inputErrors.wallet_id : ''}
                            error={inputErrors && inputErrors.wallet_id ? true : false}
                            name="wallet_id"
                            select
                            SelectProps={{
                                native: true,
                            }}
                            onChange={handleChange}
                        >
                            <option key='' value='' disabled>
                                {t('general.pleaseSelect')}
                            </option>
                            {_.map(walletList, (value, key) => {
                                return (
                                    <option key={key} value={value.id}>
                                        {value.name[i18n.language] ? value.name[i18n.language] : value.name.en}
                                    </option>
                                )
                            })}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.action`)}
                            variant="outlined"
                            value={state.wallet_action}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.wallet_action ? inputErrors.wallet_action : ''}
                            error={inputErrors && inputErrors.wallet_action ? true : false}
                            name="wallet_action"
                            select
                            SelectProps={{
                                native: true,
                            }}
                            onChange={handleChange}
                        >
                            <option key='' value='' disabled>
                                {t('general.pleaseSelect')}
                            </option>
                            {_.map(action, (value, key) => (
                                <option key={key} value={value.code}>
                                    {value.label}
                                </option>
                            ))}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.amount`)}
                            variant="outlined"
                            value={state.amount}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                            error={inputErrors && inputErrors.amount ? true : false}
                            name="amount"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.remark`)}
                            variant="outlined"
                            value={state.remark}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.remark ? inputErrors.remark : ''}
                            error={inputErrors && inputErrors.remark ? true : false}
                            name="remark"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.confirm')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    }
}))

export default WalletAdjustment