import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Grid, IconButton, InputAdornment, Link, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const PasswordProfile = () => {
    const [showPassword, setShowPassword] = useState({
        current_password: false,
        password: false,
        password_confirmation: false,
    });
    const [state, setState] = useState({
        current_password: "",
        password: "",
        password_confirmation: "",
        sync: false
    });
    const [inputErrors, setInputErrors] = useState({
        current_password: null,
        password: null,
        password_confirmation: null
    })

    const { addAlert, setLoading } = useNotificationLoading();
    const styles = useStyles();
    const { t } = useTranslation();
    const { id } = useSelector(state => state.user);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const updatePassword = () => {
        setLoading(true);
        setInputErrors();
        putUrl(`/user/password`, state).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, current_password: "", password: "", password_confirmation: "" });
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <div style={{ paddingBottom: '5%' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box component="form" noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                            <TextField
                                name="current_password"
                                label={t('user.currentPassword')}
                                type={showPassword.current_password ? 'text' : 'password'}
                                value={state.current_password}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                helperText={inputErrors && inputErrors.current_password ? (_.isArray(inputErrors.current_password) ? inputErrors.current_password[0] : inputErrors.current_password) : ''}
                                error={inputErrors && inputErrors.current_password ? true : false}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword('current_password')}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword.current_password ? <MdVisibility /> : <MdVisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box component="form" noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                            <TextField
                                name="password"
                                label={t('user.newPassword')}
                                type={showPassword.password ? 'text' : 'password'}
                                value={state.password}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                helperText={inputErrors && inputErrors.password ? (_.isArray(inputErrors.password) ? inputErrors.password[0] : inputErrors.password) : ''}
                                error={inputErrors && inputErrors.password ? true : false}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword('password')}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword.password ? <MdVisibility /> : <MdVisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box component="form" noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                            <TextField
                                name="password_confirmation"
                                label={t('user.confirmPassword')}
                                type={showPassword.password_confirmation ? 'text' : 'password'}
                                value={state.password_confirmation}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined" helperText={inputErrors && (_.isArray(inputErrors.password_confirmation) ? inputErrors.password_confirmation[0] : inputErrors.password_confirmation) ? inputErrors.password_confirmation : ''}
                                error={inputErrors && inputErrors.password_confirmation ? true : false}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword('password_confirmation')}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword.password_confirmation ? <MdVisibility /> : <MdVisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link underline='none' to={`/dashboard`} component={RouterLink}>
                        <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                    </Link>
                    <Button size="large" variant="contained" className={styles.buttonStyle} onClick={updatePassword}>{t('button.save')}</Button>
                </Box>
            </div>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    subText: {
        fontSize: '20px!important',
        color: theme.palette.gray.ltext,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },

}));

export default PasswordProfile