import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import useNotificationLoading from '@utils/useNotificationLoading';

// MUI
import { Breadcrumbs, Box, Tabs, Tab , Grid, Link, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import PropTypes from 'prop-types';
import BackToPrevious from '@layouts/BackToPrevious';
import ImagesDetail from '@pages/Notice/Image/Detail';

export default function NoticeImage() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, color: theme.palette.gray.dtext }}>
                    <Typography style={{ paddingBottom: 15, fontSize: 18 , color: theme.palette.gray.main}}><b>{t(`title.noticeImage`)}</b></Typography>
                </div>
                <div style={{ paddingBottom: 15 }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Typography color="text.primary">{t(`title.noticeImage`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>

            {/* <div>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={t('title.bannerEn')} {...a11yProps(0)} />
                    <Tab label={t('title.bannerCn')} {...a11yProps(1)} />
                </Tabs>
            </div>
            <CustomTabPanel value={value} index={0}>
                <ImagesDetail  />
            </CustomTabPanel> */}
            <ImagesDetail  />
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150,
        borderRadius: 8,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    },
    uploadMedia: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#fff",
        boxShadow: "3px 3px 20px 0 #d8d8d8",
        borderRadius: 15,
        border: "2px dashed #aeaeae",
        width: "100%",
        height: 170,
        marginTop: 15,
        padding: 20,
    },
    deleteIconStyle: {
        position: "absolute",
        right: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "black",
        cursor: 'pointer'
    },
}));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
  