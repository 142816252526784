import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useParams, useNavigate } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Chip, LinearProgress, FormControl, Grid, InputAdornment, InputLabel, Link, MenuItem, Select, TextField, Typography, FormHelperText, FormControlLabel, Checkbox, Input, Alert, Dialog, DialogTitle, DialogContent, ImageList, ImageListItem, IconButton } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

import { getUrl, postUrl } from '@utils/ApiAction';
import { buildFormData } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import BackToPrevious from '@layouts/BackToPrevious';
import { Editor } from '@tinymce/tinymce-react';

import { IoAttach, IoTrashOutline, IoCheckmark, IoClose } from "react-icons/io5";

const MODULE = 'notice';
const SYSTEM_LANG = ['cn', 'en'];
const INITIAL_STATE = {
    title: { en: "", cn: "" }, type: "", contentType: "", content: { en: '', cn: '' }, popupDisplayType: "", popupStartDate: "", popupEndDate: "", coverImage: "", newImage: { en: '', cn: '' }, previousImage: "", displaySetting: { showMember: true, showAdmin: false }, status: ''
}

export default function Detail() {
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const [fieldLang, setFieldLang] = useState({
        title: 'cn',
        content: 'cn',
        image: 'cn',
    });
    const [options, setOptions] = useState({});
    const [coverImg, setCoverImg] = useState({});
    const [selectedImg, setSelectedImg] = useState({ id: "", file_name: "" });
    const [open, setOpen] = useState(false);

    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading, loading } = useNotificationLoading();
    let navigate = useNavigate();
    let { id } = useParams();
    const location = useLocation();
    const contentImageEn = useRef(null);
    const contentImageCn = useRef(null);
    const formType = location.pathname === `/notice-add` ? "add" : "edit";

    useEffect(() => {
        setLoading(true);
        getUrl(`/notice-options`).then(response => {
            if (response.status) {
                setOptions(response.data)
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl(`/get-notice-images`).then(response => {
            if (response.status) {
                setCoverImg(response?.data?.listing);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (id) {
            getUrl(`/notices/${id}`).then(response => {
                if (response.status) {
                    setState({
                        title: response?.data?.title,
                        type: response?.data?.type,
                        contentType: response?.data?.content_type,
                        content: response?.data?.content,
                        popupDisplayType: response?.data?.popup_display_type,
                        popupStartDate: response?.data?.popup_sdate === null ? "" : moment(response?.data?.popup_sdate),
                        popupEndDate: response?.data?.popup_edate === null ? "" : moment(response?.data?.popup_edate),
                        coverImage: response?.data?.cover_image,
                        newImage: INITIAL_STATE.newImage,
                        previousImage: response?.data?.content_images,
                        displaySetting: {
                            showMember: response?.data?.display_setting?.showMember === 'true' ? true : false,
                            showAdmin: response?.data?.display_setting?.showAdmin === 'true' ? true : false,
                        },
                        status: response?.data?.status
                    });

                    let filename = _.filter(coverImg, { id: response?.data?.cover_image });
                    setSelectedImg({ id: response?.data?.cover_image, file_name: filename?.[0]?.file_name });
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            setState(INITIAL_STATE);
        }
        // eslint-disable-next-line
    }, [id, coverImg]);

    const handleChange = ({ target }) => {
        const { name, value, files, checked } = target;
        switch (name) {
            case 'showAdmin':
            case 'showMember':
                setState({
                    ...state,
                    [name]: value,
                    displaySetting: {
                        ...state.displaySetting,
                        [name]: checked
                    }
                });
                break;
            case 'image':
                setState({ ...state, newImage: { ...state.newImage, [fieldLang.image]: files[0] } });
                target.value = null;
                break;
            case 'contentType':
                if (value === 'image') {
                    setState({ ...state, [name]: value, content: INITIAL_STATE.content });
                } else if (value === 'text') {
                    setState({ ...state, [name]: value, newImage: INITIAL_STATE.newImage });
                }
                break;
            case 'popupDisplayType':
                if (value === 'persistent-popup' || value === 'no-popup') {
                    setState({ ...state, [name]: value, popupStartDate: INITIAL_STATE.popupStartDate, popupEndDate: INITIAL_STATE.popupEndDate });
                } else if (value === 'date-specific-popup') {
                    setState({ ...state, [name]: value });
                }
                break;
            default:
                setState({ ...state, [name]: value });
                break;
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const submitData = () => {
        setLoading(true);
        setInputErrors({});
        let postData = {
            title: state?.title,
            type: state?.type,
            content_type: state?.contentType,
            popup_display_type: state?.popupDisplayType,
            popup_sdate: state?.popupStartDate === "" ? state?.popupStartDate : moment(state?.popupStartDate).format('YYYY-MM-DD HH:mm:ss'),
            popup_edate: state?.popupEndDate === "" ? state?.popupEndDate : moment(state?.popupEndDate).format('YYYY-MM-DD HH:mm:ss'),
            content: state?.content,
            cover_image: state?.coverImage,
            image: state?.newImage,
            display_setting: state?.displaySetting,
            _method: formType === 'edit' ? 'put' : 'post'
        };

        if (formType === 'edit') {
            postData['status'] = state?.status;
        }

        const formData = new FormData();
        buildFormData(formData, postData);
        if (formType === 'edit' && id) {
            postUrl(`/notices/${id}`, formData).then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert('', t('success.editSuccess'), 'success', '');
                } else {
                    setInputErrors(response.errors);
                    addAlert('', t('error.editError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            postUrl(`/notices`, formData).then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert('', t('success.createSuccess'), 'success', '');
                    navigate(`/notice-edit/${response.data.id}`, { replace: true });
                } else {
                    setInputErrors(response.errors);
                    addAlert('', t('error.createError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    const getImgSrcLink = (lang) => {
        let link = '/images/login_image.png';
        if (state?.newImage[lang]) {
            if (_.includes(SYSTEM_LANG, i18n.language)) {
                link = URL.createObjectURL(state?.newImage[lang]);
            } else {
                link = URL.createObjectURL(state?.newImage?.en);
            }
        } else if (_.size(state?.previousImage) > 0) {
            let tempData = _.find(state?.previousImage, { 'type': `${MODULE}_${lang}` });
            if (tempData) {
                link = tempData?.file_name;
            }
        }

        return link;
    }

    const noticeCoverDialog = () => {
        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>{t(`${MODULE}.uploadNoticeCover`)}</div>
                    <IconButton onClick={handleClose}><IoClose /></IconButton>
                </DialogTitle>
                <DialogContent>
                    <div>
                        {
                            loading
                                ? <LinearProgress style={{ width: '100%' }} />
                                : _.size(coverImg) > 0 ?
                                    <ImageList cols={3} gap={8}>
                                        {
                                            coverImg.map((item) => (
                                                <ImageListItem key={item.id} style={{ border: (selectedImg.id === item.id) ? '2px solid green' : '' }}>
                                                    <img
                                                        src={`${item.file_name}?w=164&h=164&fit=crop&auto=format`}
                                                        srcSet={`${item.file_name}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                        alt={`gallery_${item.id}`}
                                                        style={{ cursor: "pointer", width: '50ew', aspectRatio: 1 / 1 }}
                                                        name={'coverImage'}
                                                        value={item.id}
                                                        onClick={() => {
                                                            setSelectedImg({ id: item.id, file_name: item.file_name });
                                                            setState({ ...state, coverImage: item.id });
                                                            handleClose();
                                                        }}
                                                    />
                                                    {
                                                        (selectedImg.id === item.id) &&
                                                        <IoCheckmark size="1.7em" className={classes.rightIcon} />
                                                    }
                                                </ImageListItem>
                                            ))
                                        }
                                    </ImageList>
                                    : null
                        }
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.${MODULE}${formType === 'edit' ? 'Edit' : 'Add'}`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/notices`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.${MODULE}${formType === 'edit' ? 'Edit' : 'Add'}`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12}>
                    <Alert severity="info">
                        {t(`${MODULE}.popupNote`)}
                    </Alert>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.title`)}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    {
                                        _.map(SYSTEM_LANG, (value) => (
                                            <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, title: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.title === value ? theme.palette.primary.main : '#efefef', color: fieldLang.title === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                        ))
                                    }
                                </InputAdornment>,
                            }}
                            onChange={({ target }) => setState({ ...state, title: { ...state.title, [fieldLang.title]: target.value } })}
                            value={state.title && state?.title[fieldLang.title] ? state?.title[fieldLang.title] : ""}
                            helperText={inputErrors && (inputErrors['title'] || inputErrors['title.en'] || inputErrors['title.cn'])}
                            error={inputErrors && (inputErrors['title'] || inputErrors['title.en'] || inputErrors['title.cn']) ? true : false}
                            disabled={state?.status === 40 ? true : false}
                        />
                    </Box>
                </Grid>
                {
                    _.size(options?.generalTypeList) > 0 &&
                    <Grid item xs={12} sm={6} md={4}>
                        <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth error={inputErrors && inputErrors.type ? true : false}>
                                <InputLabel>{t(`${MODULE}.type`)}</InputLabel>
                                <Select
                                    value={state?.type || ''}
                                    label={t(`${MODULE}.type`)}
                                    name="type"
                                    onChange={handleChange}
                                    displayEmpty
                                    disabled={state?.status === 40 ? true : false}
                                >
                                    <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.type`)} `} --- </MenuItem>
                                    {
                                        _.map(options?.generalTypeList, (data, key) => {
                                            return <MenuItem key={key} value={key}>{data[i18n.language] ? data[i18n.language] : data?.en}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.type &&
                                    <FormHelperText style={{ color: 'red' }}>{inputErrors?.type}</FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                }
                {
                    _.size(options?.contentTypeList) > 0 &&
                    <Grid item xs={12} sm={6} md={4}>
                        <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth error={inputErrors && inputErrors.content_type ? true : false}>
                                <InputLabel>{t(`${MODULE}.contentType`)}</InputLabel>
                                <Select
                                    value={state?.contentType || ''}
                                    label={t(`${MODULE}.contentType`)}
                                    name="contentType"
                                    onChange={handleChange}
                                    displayEmpty
                                    disabled={formType === 'edit' || state?.status === 40 ? true : false}
                                >
                                    <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.contentType`)} `} --- </MenuItem>
                                    {
                                        _.map(options?.contentTypeList, (data, key) => {
                                            return <MenuItem key={key} value={key}>{data[i18n.language] ? data[i18n.language] : data?.en}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.content_type &&
                                    <FormHelperText style={{ color: 'red' }}>{inputErrors?.content_type}</FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                }
                {
                    _.size(options?.popupDisplayTypeList) > 0 &&
                    <Grid item xs={12} sm={6} md={4}>
                        <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth error={inputErrors && inputErrors.popup_display_type ? true : false}>
                                <InputLabel>{t(`${MODULE}.popupDisplayType`)}</InputLabel>
                                <Select
                                    value={state?.popupDisplayType || ''}
                                    label={t(`${MODULE}.popupDisplayType`)}
                                    name="popupDisplayType"
                                    onChange={handleChange}
                                    displayEmpty
                                    disabled={state?.status === 40 ? true : false}
                                >
                                    <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.popupDisplayType`)} `} --- </MenuItem>
                                    {
                                        _.map(options?.popupDisplayTypeList, (data, key) => {
                                            return <MenuItem key={key} value={key}>{data[i18n.language] ? data[i18n.language] : data?.en}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.popup_display_type &&
                                    <FormHelperText style={{ color: 'red' }}>{inputErrors?.popup_display_type}</FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                }
                {
                    state?.popupDisplayType === "date-specific-popup" &&
                    <>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ms-my">
                                    <MobileDatePicker
                                        value={state?.popupStartDate || null}
                                        label={t(`${MODULE}.popupStartDate`)}
                                        name="popupStartDate"
                                        onChange={(newValue) => setState({ ...state, popupStartDate: newValue })}
                                        disablePast
                                        format="YYYY-MM-DD"
                                        slotProps={{
                                            textField: {
                                                helperText: inputErrors && inputErrors.popup_sdate ? inputErrors.popup_sdate : '',
                                                error: inputErrors && inputErrors.popup_sdate ? true : false,
                                            },
                                        }}
                                        disabled={state?.status === 40 ? true : false}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ms-my">
                                    <MobileDatePicker
                                        value={state?.popupEndDate || null}
                                        label={t(`${MODULE}.popupEndDate`)}
                                        name="popupEndDate"
                                        onChange={(newValue) => setState({ ...state, popupEndDate: newValue })}
                                        disablePast
                                        format="YYYY-MM-DD"
                                        slotProps={{
                                            textField: {
                                                helperText: inputErrors && inputErrors.popup_edate ? inputErrors.popup_edate : '',
                                                error: inputErrors && inputErrors.popup_edate ? true : false,
                                            },
                                        }}
                                        disabled={state?.status === 40 ? true : false}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                    </>
                }
                {
                    _.size(options?.statusList) > 0 && formType === 'edit' &&
                    <Grid item xs={12} sm={6} md={4}>
                        <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth error={inputErrors && inputErrors.status ? true : false}>
                                <InputLabel>{t(`${MODULE}.status`)}</InputLabel>
                                <Select
                                    value={state?.status || ''}
                                    label={t(`${MODULE}.status`)}
                                    name="status"
                                    onChange={handleChange}
                                    displayEmpty
                                    disabled={state?.status === 40 ? true : false}
                                >
                                    <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.status`)} `} --- </MenuItem>
                                    {
                                        _.map(options?.statusList, (data, key) => {
                                            return <MenuItem key={key} value={key}>{data[i18n.language] ? data[i18n.language] : data?.en}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.content_type &&
                                    <FormHelperText style={{ color: 'red' }}>{inputErrors?.content_type}</FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={4} style={{ paddingLeft: 35 }}>
                    <Typography variant="body2" style={{ marginBottom: 10 }}>{t(`${MODULE}.displaySetting`)}</Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state?.displaySetting?.showAdmin}
                                    onChange={handleChange}
                                    name="showAdmin"
                                    color="primary"
                                />
                            }
                            label={t(`${MODULE}.showAdmin`)}
                            style={{ marginRight: 20 }}
                            disabled={state?.status === 40 ? true : false}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state?.displaySetting?.showMember ? true : false}
                                    onChange={handleChange}
                                    name="showMember"
                                    color="primary"
                                />
                            }
                            label={t(`${MODULE}.showMember`)}
                            style={{ marginRight: 20 }}
                            disabled={state?.status === 40 ? true : false}
                        />
                    </div>
                    {
                        inputErrors && inputErrors?.display_setting &&
                        <FormHelperText style={{ color: 'red' }}>{inputErrors?.display_setting}</FormHelperText>
                    }
                </Grid>
                <Grid item xs={12} sm={6} md={4} style={{ paddingLeft: 35 }}>
                    <Typography variant="body2" style={{ marginBottom: 10 }}>{t(`${MODULE}.noticeCover`)}</Typography>
                    {
                        selectedImg?.file_name &&
                        <div style={{ position: 'relative', paddingBottom: 15, width: 'fit-content' }}>
                            <img src={selectedImg?.file_name} style={{ width: 200, objectFit: 'cover', aspectRatio: 1 / 1 }} alt="notice" />
                            <IoTrashOutline
                                className={classes.deleteIconStyle}
                                size="1.7em"
                                onClick={() => {
                                    setSelectedImg({ id: "", file_name: "" });
                                    setState({ ...state, coverImage: "" });
                                    handleClose();
                                }}
                            />
                        </div>
                    }
                    <Button variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={handleClickOpen}>{t(`${MODULE}.uploadNoticeCover`)}</Button>
                </Grid>
                {noticeCoverDialog()}
                {
                    state?.contentType !== "" &&
                    <Grid item xs={12} style={{ paddingLeft: 35 }}>
                        <Typography variant="h6">{t(`${MODULE}.content`)}</Typography>
                    </Grid>
                }
                {
                    state?.contentType === 'image' &&
                    <>
                        <Grid item xs={12} sm={6} md={8}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <div style={{ width: 121, height: 121, display: 'flex' }}>
                                    <img src={getImgSrcLink(fieldLang.image || 'en')} alt="Notice" style={{ objectFit: 'cover' }} />
                                </div>
                                <Typography variant="subtitle2">{t('general.previewImageNote')}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 10, paddingBottom: 5 }} >
                                    {
                                        _.map(SYSTEM_LANG, (value) => (
                                            <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, image: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.image === value ? theme.palette.primary.main : '#efefef', color: fieldLang.image === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                        ))
                                    }
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Input
                                        type="file"
                                        style={{ display: 'none' }}
                                        inputRef={i18n.language === 'cn' ? contentImageCn : contentImageEn}
                                        name="image"
                                        onChange={handleChange}
                                        disabled={state?.status === 40 ? true : false}
                                    />
                                    <TextField
                                        label={t(`${MODULE}.contentType_image`)}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        value={state.newImage && state?.newImage[fieldLang.image] ? state?.newImage[fieldLang.image]?.name : ""}
                                        helperText={inputErrors && (inputErrors['image'] || inputErrors['image.en'] || inputErrors['image.cn'])}
                                        error={inputErrors && (inputErrors['image'] || inputErrors['image.en'] || inputErrors['image.cn']) ? true : false}

                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <button onClick={() => (i18n.language === 'cn' ? contentImageCn : contentImageEn).current.click()} onMouseDown={(e) => e.preventDefault()} className='upload-file-button'>
                                                        <span>{t('button.chooseFile')}</span>
                                                    </button>
                                                </InputAdornment>
                                            ),
                                            readOnly: true,
                                        }}
                                        placeholder={t('general.noFileChosen')}
                                        inputProps={{
                                            sx: {
                                                '&::placeholder': {
                                                    color: 'black',
                                                    opacity: 1, // otherwise firefox shows a lighter color
                                                },
                                            }
                                        }}
                                    />
                                    <Typography variant="caption" color="textSecondary" style={{ margin: '0px 0px 0px 10px', color: theme.palette.gray.main }}>{t('general.imageSetting', { H: '120', W: '400', S: '5120' })}</Typography>
                                </div>
                            </Box>
                        </Grid>
                    </>
                }
                {
                    state?.contentType === 'text' &&
                    <>
                        <Grid item xs={12}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <Typography>
                                    {t(`notice.uploadImageNoti`)}
                                    &nbsp; &nbsp;
                                    <Link to={'/notice-gallery'} component={RouterLink}>{t(`notice.uploadImagLink`)}</Link>
                                </Typography>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 10, paddingBottom: 5 }} >
                                    {
                                        _.map(SYSTEM_LANG, (value) => (
                                            <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, content: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.content === value ? theme.palette.primary.main : '#efefef', color: fieldLang.content === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                        ))
                                    }
                                </div>
                                <Editor
                                    apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                                    value={state.content && state.content[fieldLang.content] ? state.content[fieldLang.content] : ""}
                                    init={{
                                        // height: 170,
                                        menubar: true,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic forecolor backcolor | fontsize fontfamily color | quickimage image media |lineheight alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help',
                                    }}
                                    onEditorChange={(content, editor) => setState({ ...state, content: { ...state.content, [fieldLang.content]: content } })}
                                    disabled={state?.status === 40 ? true : false}
                                />
                                {
                                    inputErrors && (inputErrors['content'] || inputErrors['content.en'] || inputErrors['content.cn']) &&
                                    <FormHelperText style={{ color: 'red' }}>{inputErrors && (inputErrors['content'] || inputErrors['content.en'] || inputErrors['content.cn'])}</FormHelperText>
                                }
                            </Box>
                        </Grid>
                    </>
                }
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/notices`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    rightIcon: {
        position: "absolute",
        right: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "green",
        cursor: 'pointer'
    },
    deleteIconStyle: {
        position: "absolute",
        right: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "black",
        cursor: 'pointer'
    },
    leftIcon: {
        position: "absolute",
        left: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "black",
        cursor: 'pointer'
    }
}))