import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Box, Breadcrumbs, Button, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import BackToPrevious from '@layouts/BackToPrevious';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const Create = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        username: '',
        payment_method: '',
        amount: '',
        target_group_sales: '',
        admin_remark: '',
    });
    const { t, i18n } = useTranslation();
    const paymentMethods = [
        { code: "wallet", label: t(`stake.paymentMethods.wallet`) }, 
        { code: "empty", label: t(`stake.paymentMethods.empty`) },
        { code: "free", label: t(`stake.paymentMethods.free`) },
        { code: "free2", label: t(`stake.paymentMethods.free2`) },
        { code: "free3", label: t(`stake.paymentMethods.free3`) }
    ];
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        postUrl(`/stakes`, state).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                addAlert('', t('success.createSuccess'), 'success', '');
                navigate(`/stake/${data.id}`, { replace: true });
            } else {
                setInputErrors(errors);
                addAlert('', t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    /* useEffect(() => {
        setLoading(true);
        getUrl(`/active-plans`).then(response => {
            setLoading(false);
            if (response.status) {
                setPlans(response.data);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        getUrl(`/active-ranks`).then(response => {
            setLoading(false);
            if (response.status) {
                setRankList(response.data);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, []) */

    /* useEffect(() => {
        let plan = _.find(plans, function (p) { return parseInt(p.id) === parseInt(state.plan_id); });
        if (plan) {
            setState({
                ...state,
                bv: plan.bv,
                price: plan.price,
                profit_limit: plan.profit_limit,
                // service_fee: plan.service_fee,
                rank_id: plan.rank_id ? plan.rank_id : '',
                // total_distributions: plan.roi?.total_distributions,
                roi: plan.roi,
                monthly_roi: plan.extra_setting?.monthly_roi,
                min_match: plan.extra_setting?.min_match,
                min_amount: plan.extra_setting?.min_amount,
            })
        }
        // eslint-disable-next-line
    }, [state.plan_id]) */

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.stakeAdd`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/stakes`}>
                            {t(`title.stake`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.stakeAdd`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`user.username`)}
                            variant="outlined"
                            value={state.username}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            name="username"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`stake.paymentMethod`)}
                            variant="outlined"
                            value={state.payment_method}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.payment_method ? inputErrors.payment_method : ''}
                            error={inputErrors && inputErrors.payment_method ? true : false}
                            name="payment_method"
                            select
                            SelectProps={{
                                native: true,
                            }}
                            onChange={handleChange}
                        >
                            <option key='' value='' disabled>
                                {t('general.pleaseSelect')}
                            </option>
                            {_.map(paymentMethods, (value, key) => (
                                <option key={key} value={value.code}>
                                    {value.label}
                                </option>
                            ))}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`stake.amount`)}
                            variant="outlined"
                            value={state.amount}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                            error={inputErrors && inputErrors.amount ? true : false}
                            name="amount"
                            type="number"
                            onChange={handleChange}
                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`stake.target_group_sales`)}
                            variant="outlined"
                            value={state.target_group_sales}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.target_group_sales ? inputErrors.target_group_sales : ''}
                            error={inputErrors && inputErrors.target_group_sales ? true : false}
                            name="target_group_sales"
                            type="number"
                            onChange={handleChange}
                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                        />
                        <Typography variant="body2" color="gray">{t('stake.target_group_sales_desc')}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`planHistories.adminRemark`)}
                            variant="outlined"
                            value={state.admin_remark}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.admin_remark ? inputErrors.admin_remark : ''}
                            error={inputErrors && inputErrors.admin_remark ? true : false}
                            name="admin_remark"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>
            
            <Typography variant="body2" color="gray">Payment Method:</Typography>
            <Typography variant="body2" color="gray">Wallet: Normal</Typography>
            <Typography variant="body2" color="gray">Empty: No ROI, Earn Bonus, No Generate Bonus</Typography>
            <Typography variant="body2" color="gray">Free1: Locked ROI, Earn Bonus, No Generate Bonus</Typography>
            <Typography variant="body2" color="gray">Free2: Earn ROI, Earn Bonus, No Generate Bonus</Typography>
            <Typography variant="body2" color="gray">Free3: Earn ROI, Earn Bonus, No Generate Bonus</Typography>
            
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/stake`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}))

export default Create