import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { putUrl } from '@utils/ApiAction';
import { Link, TextField, Box, Button, Grid, FormControl, InputLabel, Select, FormHelperText, MenuItem } from '@mui/material';
import COUNTRIES from '@utils/CountryList';
import { useSelector } from 'react-redux';

const MODULE = 'user';
const INITIAL_STATE = {
    username: '',
    name: '',
    mobile: '',
    email: '',
    country: '',
    status: 0,
    referralName: '',
    placementName: '',
    placementPosition: '',
    leadershipRank: 0,
    crypto_address: ''
}

const BasicInfo = (props) => {
    let { userInfo, statusList, leadershipRankList } = props;
    let { id } = useParams();
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const { addAlert, setLoading } = useNotificationLoading();
    const POSITION = [
        { 'code': 'left', 'value': 1, label: t('general.left') },
        { 'code': 'right', 'value': 2, label: t('general.right') }
    ];
    const { permissions } = useSelector(state => state.general);

    useEffect(() => {
        if (userInfo) {
            setState({ ...state, username: userInfo?.username, name: userInfo?.name, email: userInfo?.email, mobile: userInfo?.mobile, status: userInfo?.status, referralName: userInfo?.referral_name, placementName: userInfo?.placement_name, placementPosition: userInfo?.placement_position, mrank: userInfo?.mrank_id, leadershipRank: userInfo?.cmrank_id, alwaysActive: userInfo?.data?.always_active, country:userInfo?.country, crypto_address:userInfo?.wallets?.general?.address });
        }
        // eslint-disable-next-line
    }, [userInfo])

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            name: state.name,
            email: state.email,
            mobile: state.mobile,
            country: state.country,
            status: state.status,
            referral_name:state?.referralName,
            placement_name:state?.placementName,
            placement_position:state?.placementPosition,
            leadership_rank: state.leadershipRank,
            always_active: state.alwaysActive,
            crypto_address: state.crypto_address
        }
        putUrl(`/members/${id}`, params).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div>
            <Box component="form" noValidate sx={{ '& .MuiFormControl-root': { m: 1, width: '100%' } }} >
                <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t(`${MODULE}.username`)}
                            variant="outlined"
                            value={state.username || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            name="username"
                            onChange={handleChange}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t(`${MODULE}.name`)}
                            variant="outlined"
                            value={state.name || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                            error={inputErrors && inputErrors.name ? true : false}
                            name="name"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t(`${MODULE}.mobile`)}
                            variant="outlined"
                            value={state.mobile || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                            error={inputErrors && inputErrors.mobile ? true : false}
                            name="mobile"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t(`${MODULE}.email`)}
                            variant="outlined"
                            type="email"
                            value={state.email || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            name="email"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            disabled={!(_.includes(permissions, `edit-genealogy`))}
                            variant="outlined"
                            name='referralName'
                            label={t(`${MODULE}.referralName`)}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.referral_name ? inputErrors.referral_name : ''}
                            error={inputErrors && inputErrors.referral_name ? true : false}
                            InputLabelProps={{ shrink: true }}
                            value={state.referralName || ''}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            variant="outlined"
                            name='placementName'
                            label={t(`${MODULE}.placementName`)}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.placement_name ? inputErrors.placement_name : ''}
                            error={inputErrors && inputErrors.placement_name ? true : false}
                            InputLabelProps={{ shrink: true }}
                            value={state.placementName || ''}
                            disabled
                        />
                    </Grid> */}
                    {/* {
                        _.size(POSITION) > 0 &&
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth error={inputErrors && inputErrors.placement_position ? true : false}>
                                <InputLabel>{t(`${MODULE}.placementPosition`)}</InputLabel>
                                <Select
                                    value={state?.placementPosition || ''}
                                    label={t(`${MODULE}.placementPosition`)}
                                    name="placementPosition"
                                    onChange={handleChange}
                                    displayEmpty
                                >
                                    <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.placementPosition`)} `} --- </MenuItem>
                                    {
                                        _.map(POSITION, (data, key) => {
                                            return <MenuItem key={key} value={data.value}>{data.label}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.placement_position &&
                                    <FormHelperText style={{ color: 'red' }}>{inputErrors?.placement_position}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                    } */}
                    {
                        _.size(leadershipRankList) > 0 &&
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth >
                                <InputLabel>{t(`${MODULE}.mrank`)}</InputLabel>
                                <Select
                                    value={state?.mrank || ''}
                                    label={t(`${MODULE}.mrank`)}
                                    name="mrank"
                                    onChange={handleChange}
                                    displayEmpty
                                    disabled={true}
                                >
                                    <MenuItem key={-1} value={-1} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.leadershipRank`)} `} --- </MenuItem>
                                    {
                                        _.map(leadershipRankList, (data) => {
                                            return <MenuItem key={data.id} value={data.id}>{data.name[i18n.resolvedLanguage]}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    }
                    {
                        _.size(leadershipRankList) > 0 &&
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth error={inputErrors && inputErrors.status ? true : false}>
                                <InputLabel>{t(`${MODULE}.cmrank`)}</InputLabel>
                                <Select
                                    value={state?.leadershipRank || ''}
                                    label={t(`${MODULE}.cmrank`)}
                                    name="leadershipRank"
                                    onChange={handleChange}
                                    displayEmpty
                                >
                                    <MenuItem key={-1} value={-1} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.leadershipRank`)} `} --- </MenuItem>
                                    {
                                        _.map(leadershipRankList, (data) => {
                                            return <MenuItem key={data.id} value={data.id}>{data.name[i18n.resolvedLanguage]}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.status &&
                                    <FormHelperText style={{ color: 'red' }}>{inputErrors?.status}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                    }
                    {/* <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth error={inputErrors && inputErrors.status ? true : false}>
                            <InputLabel>{t(`${MODULE}.alwaysActive`)}</InputLabel>
                            <Select
                                value={state?.alwaysActive || 0}
                                label={t(`${MODULE}.alwaysActive`)}
                                name="alwaysActive"
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem key={-1} value={-1} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.alwaysActive`)} `} --- </MenuItem>
                                {
                                    _.map([0,1], (data) => {
                                        return <MenuItem key={data} value={data}>{data}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText style={{ color: 'gray' }}>User can get bonus without fulfilling daily game play requirement.</FormHelperText>
                            {
                                inputErrors && inputErrors?.status &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.status}</FormHelperText>
                            }
                        </FormControl>
                    </Grid> */}
                    {
                        _.size(statusList) > 0 &&
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth error={inputErrors && inputErrors.status ? true : false}>
                                <InputLabel>{t(`${MODULE}.status`)}</InputLabel>
                                <Select
                                    value={state?.status || ''}
                                    label={t(`${MODULE}.status`)}
                                    name="status"
                                    onChange={handleChange}
                                    displayEmpty
                                >
                                    <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.status`)} `} --- </MenuItem>
                                    {
                                        _.map(statusList, (data, key) => {
                                            return <MenuItem key={key} value={key}>{i18n.language === 'cn' ? data[i18n.language] : data?.en}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.status &&
                                    <FormHelperText style={{ color: 'red' }}>{inputErrors?.status}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                    }
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth error={inputErrors && inputErrors.country ? true : false}>
                            <InputLabel>{t(`${MODULE}.country`)}</InputLabel>
                            <Select
                                value={state?.country || ''}
                                label={t(`${MODULE}.country`)}
                                name="country"
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem key={0} value={0} disabled>--- {`${t('general.selectCountry')} ${t(`${MODULE}.country`)} `} --- </MenuItem>
                                {
                                    _.map(COUNTRIES, (data, key) => {
                                        return <MenuItem key={key} value={data.value}>{data.label}</MenuItem>
                                    })
                                }
                            </Select>
                            {
                                inputErrors && inputErrors?.country &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.country}</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Withdrawal Address"
                            variant="outlined"
                            value={state.crypto_address || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.crypto_address ? inputErrors.crypto_address : ''}
                            error={inputErrors && inputErrors.crypto_address ? true : false}
                            name="crypto_address"
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link underline='none' to={`/members`} component={RouterLink}>
                        <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                    </Link>
                    <Button size="large" variant="contained" className={styles.buttonStyle} onClick={submitData}>{t('button.edit')}</Button>
                </Box>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}));

export default BasicInfo;