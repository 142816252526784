import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { putUrl } from '@utils/ApiAction';
import { Link, Typography, Box, Button, Grid, FormControl, InputLabel, Select, FormHelperText, MenuItem, Checkbox, FormControlLabel } from '@mui/material';

const MODULE = 'user';
const INITIAL_STATE = {
    roleType: [],
    permissions: [],
    directPermissions: [],
}

const RolePermission = (props) => {
    let { userInfo, roleList, permission, rolePermissions } = props;
    let { id } = useParams();
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const [rolePermission, setRolePermission] = useState([]);
    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        if (userInfo) {
            let listing = [];
            if (_.size(userInfo?.roles) > 0) {
                _.map(userInfo?.roles, (roleList, index) => {
                    listing[index] = roleList.name;
                })
            }
            setState({ ...state, roleType: listing, permissions: userInfo?.permissions, directPermissions: userInfo?.direct_permissions });
        }
        // eslint-disable-next-line
    }, [userInfo]);

    useEffect(() => {
        let permissionList = [];

        if (_.size(state.roleType) > 0) {
            _.map(state.roleType, (roleList) => {
                let permissionDetail = rolePermissions[roleList];
                if (_.size(permissionDetail) > 0) {
                    _.map(permissionDetail, detail => {
                        if (!_.includes(permissionList, detail)){
                            permissionList.push(detail);
                        }
                    })
                }
                setRolePermission(permissionList);
            });
        } else {
            setRolePermission([]);
        }
        // eslint-disable-next-line
    }, [state.roleType, rolePermissions]);

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            role_type:state?.roleType,
            permissions: state?.directPermissions,
        }
        putUrl(`/admins/${id}/role-permission`, params).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response?.message ||  t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const changeAuthorize = name => {
        let permissionList = state?.directPermissions;
        // const checkpermission = permissionList.indexOf(name);
        let permissions = _.clone(permission);
        let temp_arr = [];
        // console.log("allPermission", permissions);
        // console.log("permission before", permissionList);

        let parentPermission = _.find(permissions, { 'name': name });
        // console.log("parentPermisison", parentPermission);
        if (!_.isUndefined(parentPermission)) {
            let checkParentInPermission = permissionList.indexOf(parentPermission.name);
            if (checkParentInPermission !== -1) {
                if (_.size(parentPermission.children) > 0) {
                    _.map(parentPermission.children, child => {
                        let checkChildInPermission = permissionList.indexOf(child.name)
                        if (checkChildInPermission !== -1) {
                            temp_arr.push(child.name);
                        }
                    })
                }
            } else {
                if (_.size(parentPermission.children) > 0) {
                    _.map(parentPermission.children, child => {
                        temp_arr.push(child.name);
                    })
                }
            }
            temp_arr.push(parentPermission.name);
        } else {
            let selectedPermission = '';
            for (const listing of permissions) {
                if (_.size(listing.children) > 0) {
                    for (const value of listing.children) {
                        if (value.name === name) {
                            selectedPermission = listing;
                            break;
                        }
                    }
                }
                if (_.size(selectedPermission) > 0) break;
            }

            temp_arr.push(name);
            temp_arr.push(selectedPermission.name);

            // console.log("selectedPermission", selectedPermission)
        }

        // console.log("temparr", temp_arr);

        _.map(temp_arr, data => {
            // console.log("data check", data)
            let checkParent = _.find(permissions, { 'name': data });

            if (_.size(checkParent) > 0) {
                if (_.size(checkParent.children) > 0) {
                    let countChild = 0;
                    _.map(checkParent.children, child => {
                        let checkChildInPermission = permissionList.indexOf(child.name)
                        if (checkChildInPermission !== -1) {
                            countChild = countChild + 1;
                        }
                    })

                    let checkpermission = permissionList.indexOf(data);
                    if (countChild === 0) {
                        permissionList.splice(checkpermission, 1);
                    } else if (checkpermission === -1) {
                        permissionList.push(data);
                    }
                } else {
                    let checkpermission = permissionList.indexOf(data);
                    if (checkpermission !== -1) {
                        permissionList.splice(checkpermission, 1);
                    } else {
                        permissionList.push(data);
                    }
                }
            } else {
                let checkpermission = permissionList.indexOf(data);
                if (checkpermission !== -1) {
                    permissionList.splice(checkpermission, 1);
                } else {
                    permissionList.push(data);
                }
            }
        })


        setState({ ...state, directPermissions: [...permissionList] });
    }

    const authorizeRole = ({target}) => {
        let { name, value } = target;
        let roleAuthorize = [];
        let permissionList = [];
        let selectedPermissions =_.clone(state?.directPermissions);

        // if (_.includes(roleAuthorize, value)) {

        //     roleAuthorize = _.difference(roleAuthorize, [value]);
        // } else {
            roleAuthorize.push(value);
        // }

        if (_.size(roleAuthorize) > 0) {
            _.map(roleAuthorize, (roleList) => {
                let permissionDetail = rolePermissions[roleList];
                if (_.size(permissionDetail) > 0) {
                    _.map(permissionDetail, detail => {
                        if (!_.includes(permissionList, detail)){
                            permissionList.push(detail);
                        }
                    })
                }

                if(_.size(permissionList) > 0){
                    _.map(permissionList , list=>{
                        if(_.includes(selectedPermissions, list)){
                            selectedPermissions = _.difference(selectedPermissions , [list]);
                        }
                    })
                }
                setRolePermission(permissionList);
            });
        } else {
            setRolePermission([]);
        }

        setState({ ...state, [name]: roleAuthorize , directPermissions: [...selectedPermissions] });
    }

    return (
        <div>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                {
                    _.size(roleList) > 0 &&
                    <Grid item xs={12} sm={6} md={4}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth error={inputErrors && inputErrors.role_type ? true : false}>
                                <InputLabel>{t(`${MODULE}.roleType`)}</InputLabel>
                                <Select
                                    value={state?.roleType || ''}
                                    label={t(`${MODULE}.roleType`)}
                                    name="roleType"
                                    onChange={authorizeRole}
                                    displayEmpty
                                >
                                    <MenuItem key={0} value={0}>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.roleType`)} `} --- </MenuItem>
                                    {
                                        _.map(roleList, (data, key) => {
                                            return <MenuItem key={key} value={data.name}>{i18n.language === 'cn' ? data?.display_name[i18n.language] : data?.display_name?.en}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.role_type &&
                                    <FormHelperText style={{ color: 'red' }}>{inputErrors?.role_type}</FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                }
                {
                    _.size(roleList) > 0 &&
                    <Grid item xs={12}>
                        <div style={{ paddingBottom: 5 }}>
                            <Typography className={styles.subText} style={{ paddingBottom: 6 }}><b>{t('title.permissions')}</b></Typography>
                            <Box sx={{ width: '100%' }}>
                                <Grid container>
                                    {
                                        _.size(permission) > 0 &&
                                        _.map(permission, listing => {
                                            let selectedPermission = false;
                                            let adminSelectedPermission = false;
                                            if (_.size(rolePermission) > 0) {
                                                _.map(rolePermission, list => {
                                                    if (listing.name === list) {
                                                        selectedPermission = true;
                                                    }
                                                });
                                            }
                                            if (_.size(state?.directPermissions) > 0) {
                                                _.map(state?.directPermissions, admin => {
                                                    if (listing.name === admin) {
                                                        adminSelectedPermission = true;
                                                    }
                                                });
                                            }
                                            if (listing.authorize) {
                                                return (
                                                    <Grid item xs={12} sm={6} md={4} key={listing.id}>
                                                        <FormControlLabel
                                                            control={<Checkbox checked={selectedPermission ? true : (adminSelectedPermission ? true : false)} color="primary" onChange={() => changeAuthorize(listing.name)} disabled={selectedPermission} />}
                                                            label={listing.name}
                                                        />
                                                        {
                                                            _.size(listing.children) > 0 && _.map(listing.children, child => {
                                                                let selectedChildPermission = false;
                                                                let adminSelectedChildPermission = false;
                                                                if (_.size(rolePermission) > 0) {
                                                                    _.map(rolePermission, list => {
                                                                        if (child.name === list) {
                                                                            selectedChildPermission = true;
                                                                        }
                                                                    });
                                                                }
                                                                if (_.size(state?.directPermissions) > 0) {
                                                                    _.map(state?.directPermissions, adminChild => {
                                                                        if (child.name === adminChild) {
                                                                            adminSelectedChildPermission = true;
                                                                        }
                                                                    });
                                                                }
                                                                if (child.authorize) {
                                                                    return (
                                                                        <Box key={child.id} spacing={2} paddingLeft={5}>
                                                                            <FormControlLabel
                                                                                control={<Checkbox checked={selectedChildPermission ? true : (adminSelectedChildPermission ? true : false)} color="primary" onChange={() => changeAuthorize(child.name)} disabled={selectedChildPermission} />}
                                                                                label={child.name}
                                                                            />
                                                                        </Box>
                                                                    )
                                                                } else { return null; }
                                                            })
                                                        }
                                                    </Grid>
                                                )
                                            } else { return null; }
                                        })
                                    }
                                </Grid>
                            </Box>
                        </div>
                    </Grid>
                }
            </Grid>
            <Box style={{ paddingTop: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link underline='none' to={`/admins`} component={RouterLink}>
                    <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={styles.buttonStyle} onClick={submitData}>{t('button.edit')}</Button>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}));

export default RolePermission;