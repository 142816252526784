import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, FormControl, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

export default function Edit() {
    const [inputErrors, setInputErrors] = useState({});
    const [stake, setStake] = useState({
        amount: 0,
        profit_limit: 0,
        profit_limit_balance: 0,
        target_group_sales: 0,
        status: '',
    });
    const [statusList, setStatusList] = useState({});

    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        setLoading(true);
        getUrl(`/stakes/${id}`).then(response => {
            setLoading(false);
            const { data } = response;
            if (response.status) {
                setStake(data);
                setStatusList(response.status_list);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setStake({ ...stake, [name]: value });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        putUrl(`/stakes/${id}`, stake).then(response => {
            setLoading(false);
            const { status, errors } = response;
            if (status) {
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.stakeEdit`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/stakes`}>
                            {t(`title.stake`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.stakeEdit`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container style={{ marginLeft: 10, marginBottom: 20 }}>
                <Grid item xs={4}>
                    <Typography>ID</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography style={{ wordBreak: 'break-all' }}>: {stake.id}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography>{t(`user.username`)}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography style={{ wordBreak: 'break-all' }}>: {stake?.user?.username}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography>{t(`general.createdAt`)}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography style={{ wordBreak: 'break-all' }}>: {stake.created_at}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography>{t(`plans.payment`)}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography style={{ wordBreak: 'break-all' }}>: {stake.payment_method || '-'}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography>{t(`stake.paymentMethod`)}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography style={{ wordBreak: 'break-all' }}>: {stake.payment_display || '-'}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`stake.amount`)}
                            variant="outlined"
                            value={stake.amount || ''}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                            error={inputErrors && inputErrors.amount ? true : false}
                            name="amount"
                            type="number"
                            onChange={handleChange}
                            sx={{
                                backgroundColor: 'lightgrey',
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            className={classes.noStepper}
                            label={t(`plans.profitLimit`)}
                            variant="outlined"
                            value={stake.profit_limit || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.profit_limit ? inputErrors.profit_limit : ''}
                            error={inputErrors && inputErrors.profit_limit ? true : false}
                            name="profit_limit"
                            type="number"
                            onChange={handleChange}
                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            className={classes.noStepper}
                            label={t(`plans.profitLimitBalance`)}
                            variant="outlined"
                            value={stake.profit_limit_balance || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.profit_limit_balance ? inputErrors.profit_limit_balance : ''}
                            error={inputErrors && inputErrors.profit_limit_balance ? true : false}
                            name="profit_limit_balance"
                            type="number"
                            onChange={handleChange}
                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            className={classes.noStepper}
                            label={t(`stake.target_group_sales`)}
                            variant="outlined"
                            value={stake.target_group_sales || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.target_group_sales ? inputErrors.target_group_sales : ''}
                            error={inputErrors && inputErrors.target_group_sales ? true : false}
                            name="target_group_sales"
                            type="number"
                            onChange={handleChange}
                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`planHistories.status`)}</InputLabel>
                            <Select
                                value={stake.status}
                                label={t(`planHistories.status`)}
                                name="status"
                                onChange={({ target }) => setStake({ ...stake, status: target.value })}
                                error={inputErrors && inputErrors.status ? true : false}
                            >
                                {_.map(statusList, (display, skey) => {
                                    return <MenuItem key={skey} value={skey}>{display?.[i18n.language] ? display?.[i18n.language] : display?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</MenuItem>
                                })}
                            </Select>
                            {
                                inputErrors && inputErrors.status
                                    ?
                                    <Typography variant="caption" style={{ color: theme.palette.red.main }}>{inputErrors.status}</Typography>
                                    : null
                            }
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`general.admin_remark`)}
                            variant="outlined"
                            value={stake.admin_remark || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.admin_remark ? inputErrors.admin_remark : ''}
                            error={inputErrors && inputErrors.admin_remark ? true : false}
                            name="admin_remark"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/stakes`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    }
}));