import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { AddBoxOutlined, Edit } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import CustomizeMaterialReactTable from '@layouts/CustomizeMaterialReactTable';

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    useEffect(() => {
        setTableLoading(true);
        getUrl(`/wallets`).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data);
            } else {
                addAlert("", response.message, 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const columns = useMemo(() => ([
        {
            header: `${t('table.actions')}`,
            accessorKey: 'actions',
            Cell: ({ cell }) => (
                _.includes(permissions, `wallet-edit`) ?
                    <Link underline='none' to={`/wallet-edit/${cell.row.original.id}`} component={RouterLink}>
                        <Edit style={{ color: theme.palette.button.main }} />
                    </Link>
                    : null
            ),
            enableSorting: false,
            enableColumnFilter: false,
            maxSize: 50
        },
        {
            header: `${t(`wallets.code`)}`,
            accessorKey: 'code',
        },
        {
            header: `${t(`wallets.name`)}`,
            id: 'wallet_name',
            accessorFn: (row) => row.name && (row.name[i18n.language] ? row.name[i18n.language] : row.name.en),
            Cell: ({ cell }) => (
                <Box>{cell.row.original.name && (cell.row.original.name[i18n.language] ? cell.row.original.name[i18n.language] : cell.row.original.name.en)}</Box>
            ),
        },
        {
            header: `${t(`wallets.decimal`)}`,
            accessorKey: 'decimal',
        },
        {
            header: `${t(`wallets.rate`)}`,
            accessorKey: 'rate',
        },
        {
            header: `${t(`wallets.allowNegative`)}`,
            id: 'allow_negative',
            accessorFn: (row) => row.allow_negative ? t('general.true') : t('general.false'),
            Cell: ({ cell }) => (
                <Box>{cell.row.original.allow_negative ? t('general.true') : t('general.false')}</Box>
            ),
        },
        {
            header: `${t(`general.createdAt`)}`,
            accessorKey: 'created_at',
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.wallets`)}</b></Typography>
                    {
                        _.includes(permissions, "wallet-create") &&
                        <Link underline='none' to={`/wallet-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                    }
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.wallets`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <CustomizeMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={false}
            />
        </>
    )
}

export default Listing