import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { putUrl, getUrl } from '@utils/ApiAction';
import { Link, TextField, Box, Button, Grid, FormControl, InputLabel, Select, FormHelperText, MenuItem } from '@mui/material';

const MODULE = 'user';
const INITIAL_STATE = {
    username: '',
    name: '',
    mobile: '',
    email: '',
    status: 0,
}

const BasicInfo = (props) => {
    let { userInfo, statusList } = props;
    let { id } = useParams();
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        if (userInfo) {
            setState({ ...state, username: userInfo?.username, name: userInfo?.name, email: userInfo?.email, mobile: userInfo?.mobile, status: userInfo?.status });
        }
        // eslint-disable-next-line
    }, [userInfo])

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            name: state.name,
            email: state.email,
            mobile: state.mobile,
            status: state.status,
            referral_name: state?.referralName,
            placement_name: state?.placementName,
            placement_position: state?.placementPosition
        }
        putUrl(`/admins/${id}`, params).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response?.message ||  t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.username`)}
                            variant="outlined"
                            value={state.username || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            name="username"
                            onChange={handleChange}
                            disabled
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.name`)}
                            variant="outlined"
                            value={state.name || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                            error={inputErrors && inputErrors.name ? true : false}
                            name="name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.mobile`)}
                            variant="outlined"
                            value={state.mobile || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                            error={inputErrors && inputErrors.mobile ? true : false}
                            name="mobile"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                {
                    _.size(statusList) > 0 &&
                    <Grid item xs={12} sm={6} md={4}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth error={inputErrors && inputErrors.status ? true : false}>
                                <InputLabel>{t(`${MODULE}.status`)}</InputLabel>
                                <Select
                                    value={state?.status || ''}
                                    label={t(`${MODULE}.status`)}
                                    name="status"
                                    onChange={handleChange}
                                    displayEmpty
                                >
                                    <MenuItem key={0} value={0}>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.status`)} `} --- </MenuItem>
                                    {
                                        _.map(statusList, (data, key) => {
                                            return <MenuItem key={key} value={key}>{i18n.language === 'cn' ? data[i18n.language] : data?.en}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.status &&
                                    <FormHelperText style={{ color: 'red' }}>{inputErrors?.status}</FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                }
            </Grid>
            <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link underline='none' to={`/admins`} component={RouterLink}>
                    <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={styles.buttonStyle} onClick={submitData}>{t('button.edit')}</Button>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}));

export default BasicInfo;