import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        console.log('apiData', apiData);
        getUrl(`/report-wallet-balance`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.data);
                setRowCount(response.data.total);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    const columns = useMemo(() => ([
        {
            header: `${t(`wallets.username`)}`,
            id: 'wallet_balanceable.username',
            accessorFn: (row) => (row.wallet_balanceable?.username),
            enableSorting: false,
        },
        {
            header: `${t(`wallets.email`)}`,
            id: 'wallet_balanceable.email',
            accessorFn: (row) => (row.wallet_balanceable?.email),
            enableSorting: false,
        },
        {
            // use for export, hide for display
            header: `${t(`wallets.name`)}`,
            accessorKey: 'wallet.name.' + i18n.language,
        },
        {
            // use for display, hide for export
            header: `${t(`wallets.name`)}`,
            id: 'wallet.name',
            Cell: ({ cell }) => (
                <Box>
                    {cell.row.original.wallet.name[i18n.language] ? cell.row.original.wallet.name[i18n.language] : cell.row.original.wallet.name.en}
                </Box>
            ),
            enableSorting: false,
            hideExport: true,
        },
        {
            header: `${t(`wallets.balance`)}`,
            id: 'balance',
            accessorFn: (row) => (parseFloat(row.balance).toFixed(4)),
        },
        {
            header: `${t(`wallets.lockedBalance`)}`,
            id: 'locked',
            accessorFn: (row) => (parseFloat(row.locked).toFixed(4)),
        },
        {
            header: `${t(`general.updatedAt`)}`,
            accessorKey: 'updated_at',
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.reportWalletBalance`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.reportWalletBalance`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'WalletBalanceList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                columnVisibility={{ ['wallet.name.' + i18n.language]: false }}
            />
        </>
    )
}

export default Listing