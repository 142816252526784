import { TreeItem, TreeView } from "@material-ui/lab";
import { Box, Collapse, FormControl, FormControlLabel, Button, Radio, RadioGroup, Grid, Icon, IconButton, Input, InputAdornment, Link, Paper, Toolbar, Tooltip, Typography } from '@mui/material';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import { getUrl } from "@utils/ApiAction";
import { stringToLang, useQuery, currencyFormat } from "@utils/Tools";
import useNotificationLoading from "@utils/useNotificationLoading";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";

import { MdAccountTree, MdAddBox, MdAttachMoney, MdClear, MdFindInPage, MdIndeterminateCheckBox, MdLocalAtm, MdPerson, MdSearch } from "react-icons/md";
import { FaUser, FaUsers } from "react-icons/fa6";

function TableToolbar(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [value, setValue] = useState(props.val);
	const [selectedType, setSelectedType] = useState(props.type);
	const searchInputRef = useRef(null);

	const handleTypeChange = (event) => {
		setSelectedType(event.target.value);
	};

	const handleClear = () => {
		setValue("");
		searchInputRef.current.focus();
	};

	const toolbarSearch = (
		<Toolbar className={classes.toolbarRoot}>
			<form noValidate onSubmit={props.handleSubmit}>
				<FormControl>
					<Input
						id="search-input"
						placeholder={t("genealogies.searchUsername")}
						startAdornment={
							<InputAdornment position="start">
								<Tooltip title={t("table.search")}>
									<Icon
										fontSize="small"
										onClick={props.handleSubmit}
									>
										<MdSearch />
									</Icon>
								</Tooltip>
							</InputAdornment>
						}
						endAdornment={
							<InputAdornment position="end">
								<Tooltip title={t("table.clear")}>
									<IconButton
										type="button"
										onClick={handleClear}
										className={classes.iconButton}
										aria-label="clear-search"
									>
										<MdClear size={25} />
									</IconButton>
								</Tooltip>
							</InputAdornment>
						}
						aria-describedby="search"
						inputProps={{
							"aria-label": "search",
						}}
						inputRef={searchInputRef}
						value={value}
						onChange={(e) => setValue(e.target.value)}
					/>
				</FormControl>

				<FormControl component="fieldset" style={{ marginLeft: 10 }}>
					<RadioGroup
						aria-label="searchBy"
						name="searchBy"
						value={selectedType}
						onChange={handleTypeChange}
						row
					>
						<FormControlLabel value="downlines" control={<Radio />} label={t('commission.downline')} />
						<FormControlLabel value="uplines" control={<Radio />} label={t('title.upline')} />
					</RadioGroup>
				</FormControl>
				<Button variant="contained" style={{ marginLeft: 10 }} onClick={props.handleSubmit}>{t('table.search')}</Button>
			</form>
		</Toolbar>
	);
	return [value, toolbarSearch, setValue, selectedType, setSelectedType];
}

function TransitionComponent(props) {
	const style = useSpring({
		from: { opacity: 0, transform: "translate3d(20px,0,0)" },
		to: {
			opacity: props.in ? 1 : 0,
			transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
		},
	});

	return (
		<animated.div style={style}>
			<Collapse {...props} />
		</animated.div>
	);
}

TransitionComponent.propTypes = {
	/**
	 * Show the component; triggers the enter or exit states
	 */
	in: PropTypes.bool,
};

const USER_STATUS_COLOR = {
	10: 'grey',
	20: 'green',
	30: 'orange',
	40: 'red'
}

export default function SponsorGenealogy() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const query = useQuery();
	const isMountedRef = useRef(null);

	const { addAlert, setLoading, loading } = useNotificationLoading();
	const [sponsorData, setSponsorData] = useState([]);
	const [errMsg, setErrMsg] = useState([]);
	const [username, setUsername] = useState("");
	const [type, setType] = useState("downlines");

	function handleSubmit(event) {
		event.preventDefault();
		setUsername(searchValue);
		setType(searchType);
	}

	const [searchValue, searchToolbar, setSearchValue, searchType, setSearchType] = TableToolbar({ handleSubmit, val: "", type: "downlines" });

	useEffect(() => {
		isMountedRef.current = true;
		setLoading(true);

		setErrMsg([]);

		getUrl(`admins/genealogy/sponsor`, { username, type })
			.then((response) => {
				let { status, message, errors, data } = response;
				if (isMountedRef.current) {
					if (status) {
						setSponsorData(data.tree_data);
						setLoading(false);
					} else if (errors) {
						setErrMsg(errors);
						if (_.size(errors) > 0) {
							_.map(errors, (value, key) => {
								message += "\n " + value[0];
							});
						}
						setLoading(false);
						addAlert("", message, "error", "");
					}
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert("", error + "\n" + t("error.contactSupport"), "error", "");
			});

		return () => {
			isMountedRef.current = false;
		};
		// eslint-disable-next-line
	}, [username, type]);

	const viewMore = (value, type) => {
		setSearchValue(value);
		setSearchType(type);
		setUsername(value);
		setType(type);
	};

	const renderTree = (nodes) => {
		let rankName = '—';
		if (nodes.rank_code || nodes.crank_code) {
			if (nodes.rank_code > nodes.crank_code) {
				let data = JSON.parse(nodes.rank_name);
				rankName = data[i18n.resolvedLanguage || 'en'];
			} else {
				let data = JSON.parse(nodes.crank_name);
				rankName = data[i18n.resolvedLanguage || 'en'];
			}
		}
		return (
			<StyledTreeItem
				key={nodes.id}
				nodeId={`${nodes.id}`} /* endIcon={<PersonIcon color={"primary"} />} */
				label={
					<div className={classes.labelRoot}>
						{type === 'downlines' && !loading && _.size(nodes.children) === 0 && nodes.total_downline > 0 ? (
							<Link
								style={{ fontSize: "0.7rem" }}
								onClick={() => viewMore(nodes.username, type)}
							>
								<Tooltip
									title={t("genealogy.loadMore")}
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<span><MdFindInPage /></span>
								</Tooltip>
							</Link>
						) : null}
						<MdPerson />
						<Typography
							pl={1}
							variant="body2"
							className={classes.labelText}
							style={{ color: USER_STATUS_COLOR[nodes.status] }}
						>
							{nodes.username}
						</Typography>
						<Typography
							pl={1}
							variant="caption"
							color="secondary"
							className={classes.labelSubText}
							style={{ fontWeight: 600 }}
						>
							{rankName}
						</Typography>
						<Typography
							pl={1}
							variant="caption"
							color="inherit"
							className={classes.labelSubText}
						>
							{/* {nodes.user_created_at} */}
							{new Date(
								nodes.user_created_at.replace(/-/g, "/")
							).toLocaleDateString()}
						</Typography>
						{/* <Typography
						variant="caption"
						color="inherit"
						className={classes.labelSubText}
					>
						{nodes.user.max_mrank_display
							? "[" +
							stringToLang(nodes.user.max_mrank_display) +
							"]"
							: ""}
					</Typography> */}
						<Tooltip
							pl={1}
							title={t("commission.downline")}
							style={{ display: "flex", alignItems: "center" }}
						>
							<Typography
								variant="caption"
								color="inherit"
								className={classes.labelSubText}
							>
								<span><MdAccountTree size={20} /></span>
								<span style={{ paddingLeft: 3 }}>{nodes.total_downline}</span>
							</Typography>
						</Tooltip>
						<Tooltip
							pl={1}
							title={t("genealogies.individualSales")}
							style={{ display: "flex", alignItems: "center" }}
						>
							<Typography
								variant="caption"
								color="inherit"
								className={classes.labelSubText}
							>
								<span><FaUser size={16} /></span>
								<span style={{ paddingLeft: 3 }}>{currencyFormat(nodes.personal_sales, 2)}</span>
							</Typography>
						</Tooltip>
						<Tooltip
							pl={1}
							title={t("genealogies.groupSales")}
							style={{ display: "flex", alignItems: "center" }}
						>
							<Typography
								variant="caption"
								color="inherit"
								className={classes.labelSubText}
							>
								<span><FaUsers size={20} /></span>
								<span style={{ paddingLeft: 3 }}>{currencyFormat(nodes.group_sales, 2)}</span>
							</Typography>
						</Tooltip>
					</div>
				}
			>
				{Array.isArray(nodes.children)
					? nodes.children.map((node) => renderTree(node))
					: null}
			</StyledTreeItem>
		)
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Typography variant="h4" component="h1">
					{t("title.sponsorGenealogy")}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Paper
					className={classes.paper}
					style={{ ...theme.box1, ...theme.p20 }}
				>
					{searchToolbar}

					{_.size(sponsorData) > 0 && sponsorData != null ? (
						<TreeView
							className={classes.root}
							defaultExpanded={["6"]}
							defaultCollapseIcon={
								<MdIndeterminateCheckBox color="primary" />
							}
							defaultExpandIcon={<MdAddBox color="primary" />}
						// defaultEndIcon={<PersonIcon color="secondary"/>}
						>
							{renderTree(sponsorData)}
						</TreeView>
					) : (
						<Box className={classes.msgBox}>
							<Typography variant="body1">
								{t("genealogies.noSponsor")}
							</Typography>
						</Box>
					)}
				</Paper>
			</Grid>
		</Grid>
	);
}

const StyledTreeItem = withStyles((theme) => ({
	group: {
		marginLeft: 7,
		paddingLeft: 18,
		marginRight: 7,
		// borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
	},
	label: {
		fontSize: 17,
	},
	iconContainer: {
		fontSize: 20,
	},
}))((props) => (
	<TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const useStyles = makeStyles((theme) => ({
	root: {
		height: "auto",
		flexGrow: 1,
		maxWidth: 400,
		paddingLeft: 10,
		paddingTop: 20
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2),
		minHeight: 440,
	},
	toolbarRoot: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
		flexDirection: "row-reverse",
	},
	msgBox: {
		textAlign: "center",
		// justifyContent:'center',
		// display:'flex',
		paddingBottom: 150,
		paddingTop: 150,
	},
	labelRoot: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0.5, 0),
	},
	labelText: {
		fontWeight: "inherit",
		marginLeft: 10,
		textTransform: "lowercase",
	},
	labelSubText: {
		marginLeft: 10,
		fontStyle: "italic",
	},
}));
