import _ from 'lodash';
import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// MUI
import { Box, Container, Link, Typography } from '@mui/material';

// NOTIFICATON AND LOADING
import NotificationLoadingProvider from "@providers/NotificationLoadingProvider";
import NotificationLoading from "@layouts/NotificationLoading";

//ICONS
import { MdDashboard, MdSettings, MdAccountTree, MdWallet, MdOutlineAttachMoney, MdAlignVerticalBottom } from "react-icons/md";
import { FaUser, FaUserGear } from "react-icons/fa6";
import { HiOutlineDocumentReport } from "react-icons/hi";

// PAGES
import Sidebar from '@layouts/Sidebar';
import Dashboard from "@pages/Dashboard";
import Login from "@pages/Auth/Login";
import ForgetPassword from "@pages/Auth/ForgetPassword";
import ResetPassword from "@pages/Auth/ResetPassword";
import Profile from "@pages/Profile";
import Role from "@pages/Role";
import RoleDetail from "@pages/Role/Detail";
import Permission from "@pages/Permission";
import PermissionDetail from "@pages/Permission/Detail";
import Wallet from "@pages/Wallet";
import WalletDetail from "@pages/Wallet/Detail";
import WalletAdjustment from "@pages/Wallet/WalletAdjustment";
import WalletActionList from "@pages/Wallet/WalletActionList";
import WalletBalanceList from "@pages/Wallet/WalletBalanceList";
import WalletTransactionList from "@pages/Wallet/WalletTransactionList";
// import WalletTransferConvert from "@pages/Wallet/WalletTransferConvert";
// import WalletWithdraw from "@pages/Wallet/WalletWithdraw";
// import Plan from "@pages/Plan";
// import PlanDetail from "@pages/Plan/Detail";
// import PlanHistory from "@pages/PlanHistory";
// import PlanHistoryCreate from "@pages/PlanHistory/Create";
// import PlanHistoryEdit from "@pages/PlanHistory/Edit";
import Stake from "@pages/Stake";
import StakeCreate from "@pages/Stake/Create";
import StakeEdit from "@pages/Stake/Edit";
import Member from "@pages/Member";
import MemberCreate from "@pages/Member/Create";
import MemberEdit from "@pages/Member/Edit";
import Admin from "@pages/Admin";
import AdminCreate from "@pages/Admin/Create";
import AdminEdit from "@pages/Admin/Edit";
import SponsorGenealogy from '@pages/Genealogy/SponsorGenealogy';
import Setting from "@pages/Setting";
import SettingDetail from "@pages/Setting/Detail";
import WalletSetting from "@pages/WalletSetting";
import WalletSettingDetail from "@pages/WalletSetting/Detail";
// import Uplines from './Genealogy/Uplines';
import Commission from '@pages/Commission';
import Bonus from '@pages/Bonus';
import BonusDetail from '@pages/Bonus/Detail';
import Rank from "@pages/Rank";
import RankDetail from "@pages/Rank/Detail";
// import XbetweenGameReport from '@pages/Report/XbetweenGameReport';
import MonthlyReport from '@pages/Report/MonthlyReport';
import Notice from "@pages/Notice";
import NoticeDetail from "@pages/Notice/Detail";
import NoticeImage from '@pages/Notice/Image/index';

const routes = [
    // category: true; if show as category, fill in category info, and state all category children
    // name in english, label in translate, children icon wont show if category
    // category: false; if show as self, put details at children

    // DASHBOARD
    {
        category: false,
        categoryName: '',
        categoryLabel: '',
        categoryIcon: null,
        categoryPermission: [],
        categorySidebar: true,
        children: [
            {
                path: "/dashboard",
                component: <Dashboard />,
                permission: [],
                sidebar: true,
                icon: <MdDashboard size="20px" />,
                title: 'title.dashboard',
            },
        ]
    },
    // STAFF
    // {
    //     category: true,
    //     categoryName: 'staff',
    //     categoryLabel: 'title.staff',
    //     categoryIcon: <MdSupervisorAccount size="20px" />,
    //     categoryPermission: ['staff', 'staff-create', 'staff-edit'],
    //     categorySidebar: true,
    //     children: [
    //         {
    //             path: "/staffs",
    //             component: <Staff />,
    //             permission: ['staff'],
    //             sidebar: true,
    //             icon: null,
    //             title: 'title.staff',
    //         },
    //         {
    //             path: "/staff-add",
    //             component: <StaffCreate />,
    //             permission: ['staff-create'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.adminAdd',
    //         },
    //         {
    //             path: "/staff-edit/:id",
    //             component: <StaffEdit />,
    //             permission: ['staff-edit'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.staffEdit',
    //         },
    //     ],
    // },
    // MEMBER
    {
        category: true,
        categoryName: 'member',
        categoryLabel: 'title.member',
        categoryIcon: <FaUser size="20px" />,
        categoryPermission: ['plan', 'plan-history', 'member', 'member-create'],
        categorySidebar: true,
        children: [
            {
                path: "/members",
                component: <Member />,
                permission: ['member'],
                sidebar: true,
                icon: null,
                title: 'title.member',
            },
            {
                path: "/member-add",
                component: <MemberCreate />,
                permission: ['member-create'],
                sidebar: false,
                icon: null,
                title: 'title.memberAdd',
            },
            {
                path: "/member-edit/:id",
                component: <MemberEdit />,
                permission: ['member-edit'],
                sidebar: false,
                icon: null,
                title: 'title.memberEdit',
            },
            //SPONSOR GENEALOGY
            {
                path: "/sponsor-genealogy",
                component: <SponsorGenealogy />,
                exact: true,
                permission: ['view-genealogy'],
                sidebar: true,
                icon: <MdAccountTree />,
                title: 'title.sponsorGenealogy',
                category: 'member',
            },
            //UPLINE
            // {
            //     path: "/upline",
            //     component: <Uplines />,
            //     exact: true,
            //     permission: ['member'],
            //     sidebar: true,
            //     icon: <MdAccountTree />,
            //     title: 'title.upline',
            //     category: 'member',
            // },
            // {
            //     path: "/plans",
            //     component: <Plan />,
            //     permission: ['plan'],
            //     sidebar: true,
            //     icon: null,
            //     title: 'title.plan',
            // },
            // {
            //     path: "/plan-add",
            //     component: <PlanDetail />,
            //     permission: ['plan-create'],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.planAdd',
            // },
            // {
            //     path: "/plan/:id",
            //     component: <PlanDetail />,
            //     permission: ['plan-edit'],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.planEdit',
            // },
            // {
            //     path: "/plan-histories",
            //     component: <PlanHistory />,
            //     permission: ['plan-history'],
            //     sidebar: true,
            //     icon: null,
            //     title: 'title.planHistory',
            // },
            // {
            //     path: "/plan-history-add",
            //     component: <PlanHistoryCreate />,
            //     permission: ['plan-history-create'],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.planHistoryAdd',
            // },
            // {
            //     path: "/plan-history/:id",
            //     component: <PlanHistoryEdit />,
            //     permission: ['plan-history-edit'],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.planHistoryEdit',
            // },
            {
                path: "/stakes",
                component: <Stake />,
                permission: ['stake'],
                sidebar: true,
                icon: null,
                title: 'title.stake',
            },
            {
                path: "/stake-add",
                component: <StakeCreate />,
                permission: ['stake-create'],
                sidebar: false,
                icon: null,
                title: 'title.stakeAdd',
            },
            {
                path: "/stake/:id",
                component: <StakeEdit />,
                permission: ['stake-edit'],
                sidebar: false,
                icon: null,
                title: 'title.stakeEdit',
            },
            {
                path: "/commission",
                component: <Commission />,
                permission: ['commission'],
                sidebar: true,
                icon: null,
                title: 'title.commission',
            },
        ]
    },
    // ADMIN
    {
        category: true,
        categoryName: 'admin',
        categoryLabel: 'title.admin',
        categoryIcon: <FaUserGear size="20px" />,
        categoryPermission: ['admin', 'admin-create', 'admin-edit'],
        categorySidebar: true,
        children: [
            {
                path: "/admins",
                component: <Admin />,
                permission: ['admin'],
                sidebar: true,
                icon: null,
                title: 'title.admin',
            },
            {
                path: "/admin-add",
                component: <AdminCreate />,
                permission: ['admin-create'],
                sidebar: false,
                icon: null,
                title: 'title.adminAdd',
            },
            {
                path: "/admin-edit/:id",
                component: <AdminEdit />,
                permission: ['admin-edit'],
                sidebar: false,
                icon: null,
                title: 'title.adminEdit',
            },
        ],
    },

    // BONUS
    {
        category: false,
        categoryName: 'bonus',
        categoryLabel: 'title.bonus',
        categoryIcon: null,
        categoryPermission: ['bonus', 'bonus-create', 'bonus-edit'],
        categorySidebar: true,
        children: [
            {
                path: "/bonus",
                component: <Bonus />,
                permission: ['bonus'],
                sidebar: true,
                icon: <MdOutlineAttachMoney size="20px" />,
                title: 'title.bonus',
            },
            {
                path: "/bonus-add",
                component: <BonusDetail />,
                permission: ['bonus-create'],
                sidebar: false,
                icon: null,
                title: 'title.bonusAdd',
            },
            {
                path: "/bonus-edit/:id",
                component: <BonusDetail />,
                permission: ['bonus-edit'],
                sidebar: false,
                icon: null,
                title: 'title.bonusEdit',
            },
        ]
    },
    // RANK
    {
        category: true,
        categoryName: 'rank',
        categoryLabel: 'title.ranks',
        categoryIcon: <MdAlignVerticalBottom size="20px" />,
        categoryPermission: ['rank', 'rank-create', 'rank-edit'],
        categorySidebar: true,
        children: [
            {
                path: "/ranks",
                component: <Rank />,
                permission: ['rank'],
                sidebar: true,
                icon: <MdAlignVerticalBottom />,
                title: 'title.ranks',
            },
            {
                path: "/rank-add",
                component: <RankDetail />,
                permission: ['rank-create'],
                sidebar: false,
                icon: null,
                title: 'title.rankAdd',
            },
            {
                path: "/rank-edit/:id",
                component: <RankDetail />,
                permission: ['rank-edit'],
                sidebar: false,
                icon: null,
                title: 'title.rankEdit',
            },
        ],
    },
    // WALLET
    {
        category: true,
        categoryName: 'wallet',
        categoryLabel: 'title.wallets',
        categoryIcon: <MdWallet size="20px" />,
        categoryPermission: ['wallet', 'wallet-create', 'wallet-edit', 'wallet-adjust', 'wallet-report'],
        categorySidebar: true,
        children: [
            {
                path: "/wallets",
                component: <Wallet />,
                permission: ['wallet'],
                sidebar: true,
                icon: <MdAccountTree />,
                title: 'title.wallets',
            },
            {
                path: "/wallet-add",
                component: <WalletDetail />,
                permission: ['wallet-create'],
                sidebar: false,
                icon: null,
                title: 'title.walletAdd',
            },
            {
                path: "/wallet-edit/:id",
                component: <WalletDetail />,
                permission: ['wallet-edit'],
                sidebar: false,
                icon: null,
                title: 'title.walletEdit',
            },
            {
                path: "/wallet-adjustment",
                component: <WalletAdjustment />,
                permission: ['wallet-adjust'],
                sidebar: true,
                icon: null,
                title: 'title.walletAdjustment',
            },
            // {
            //     path: "/wallet-transfer-convert",
            //     component: <WalletTransferConvert />,
            //     permission: ['wallet-adjust'],
            //     sidebar: true,
            //     icon: null,
            //     title: 'title.walletTransferConvert',
            // },
            // {
            //     path: "/wallet-withdraw",
            //     component: <WalletWithdraw />,
            //     permission: ['wallet-withdraw'],
            //     sidebar: true,
            //     icon: null,
            //     title: 'title.walletWithdraw',
            // },
            {
                path: "/wallet-action",
                component: <WalletActionList />,
                permission: ['wallet-action-report'],
                sidebar: true,
                icon: null,
                title: 'title.reportWalletAction',
            },
            {
                path: "/wallet-balance",
                component: <WalletBalanceList />,
                permission: ['wallet-balance-report'],
                sidebar: true,
                icon: null,
                title: 'title.reportWalletBalance',
            },
            {
                path: "/wallet-transaction",
                component: <WalletTransactionList />,
                permission: ['wallet-transaction-report'],
                sidebar: true,
                icon: null,
                title: 'title.reportWalletTransaction',
            },
        ],
    },
    // REPORT
    {
        category: true,
        categoryName: 'report',
        categoryLabel: 'title.reports',
        categoryIcon: <HiOutlineDocumentReport size="20px" />,
        categoryPermission: ['report', 'xbetween-game-report', 'wallet-action-report', 'wallet-balance-report', 'wallet-transaction-report'],
        categorySidebar: true,
        children: [
            /* {
                path: "/xbetween-game-report",
                component: <XbetweenGameReport />,
                permission: ['xbetween-game-report'],
                sidebar: true,
                icon: null,
                title: 'title.xbetweenGameReports',
            }, */
            {
                path: "/monthly-report",
                component: <MonthlyReport />,
                permission: ['monthly-report'],
                sidebar: true,
                icon: null,
                title: 'title.monthlyReports',
            },
            {
                path: "/wallet-action",
                component: <WalletActionList />,
                permission: ['wallet-action-report'],
                sidebar: true,
                icon: null,
                title: 'title.reportWalletAction',
            },
            {
                path: "/wallet-balance",
                component: <WalletBalanceList />,
                permission: ['wallet-balance-report'],
                sidebar: true,
                icon: null,
                title: 'title.reportWalletBalance',
            },
            {
                path: "/wallet-transaction",
                component: <WalletTransactionList />,
                permission: ['wallet-transaction-report'],
                sidebar: true,
                icon: null,
                title: 'title.reportWalletTransaction',
            }
        ],
    },

    // SETTING
    {
        category: true,
        categoryName: 'setting',
        categoryLabel: 'title.setting',
        categoryIcon: <MdSettings size="20px" />,
        categoryPermission: ['role', 'role-create', 'role-edit', 'permission', 'permission-create', 'permission-edit', 'notice', 'notice-create', 'notice-edit'],
        categorySidebar: true,
        children: [
            {
                path: "/roles",
                component: <Role />,
                permission: ['role'],
                sidebar: true,
                icon: <MdAccountTree />,
                title: 'title.roles',
            },
            {
                path: "/role-add",
                component: <RoleDetail />,
                permission: ['role-create'],
                sidebar: false,
                icon: null,
                title: 'title.roleCreate',
            },
            {
                path: "/role-edit/:id",
                component: <RoleDetail />,
                permission: ['role-edit'],
                sidebar: false,
                icon: null,
                title: 'title.roleEdit',
            },
            {
                path: "/permissions",
                component: <Permission />,
                permission: ['permission'],
                sidebar: true,
                icon: null,
                title: 'title.permissions',
            },
            {
                path: "/permission-add",
                component: <PermissionDetail />,
                permission: ['permission-create'],
                sidebar: false,
                icon: null,
                title: 'title.permissionCreate',
            },
            {
                path: "/permission-edit/:id",
                component: <PermissionDetail />,
                permission: ['permission-edit'],
                sidebar: false,
                icon: null,
                title: 'title.permissionEdit',
            },
            {
                path: "/setting",
                component: <Setting />,
                permission: ['setting'],
                sidebar: true,
                icon: null,
                title: 'title.setting',
            },
            {
                path: "/setting-add",
                component: <SettingDetail />,
                permission: ['setting-create'],
                sidebar: false,
                icon: null,
                title: 'title.settingCreate',
            },
            {
                path: "/setting-edit/:id",
                component: <SettingDetail />,
                permission: ['setting-edit'],
                sidebar: false,
                icon: null,
                title: 'title.settingEdit',
            },
            {
                path: "/wallet-setting",
                component: <WalletSetting />,
                permission: ['wallet-setting'],
                sidebar: true,
                icon: null,
                title: 'title.walletSetting',
            },
            {
                path: "/wallet-setting-add",
                component: <WalletSettingDetail />,
                permission: ['wallet-setting-create'],
                sidebar: false,
                icon: null,
                title: 'title.walletSettingCreate',
            },
            {
                path: "/wallet-setting-edit/:id",
                component: <WalletSettingDetail />,
                permission: ['wallet-setting-edit'],
                sidebar: false,
                icon: null,
                title: 'title.walletSettingEdit',
            },
            {
                path: "/notices",
                component: <Notice />,
                permission: ['notice'],
                sidebar: true,
                icon: null,
                title: 'title.notice',
            },
            {
                path: "/notice-add",
                component: <NoticeDetail />,
                permission: ['notice-create'],
                sidebar: false,
                icon: null,
                title: 'title.noticeAdd',
            },
            {
                path: "/notice-edit/:id",
                component: <NoticeDetail />,
                permission: ['notice-edit'],
                sidebar: false,
                icon: null,
                title: 'title.noticeEdit',
            },
            {
                path: "/notice-gallery",
                component: <NoticeImage />,
                permission: ['notice-create', 'notice-edit'],
                sidebar: true,
                icon: null,
                title: 'title.noticeImage',
            },
            {
                path: "/profile",
                component: <Profile />,
                permission: [],
                sidebar: false,
                icon: null,
                title: 'title.profile',
            },
        ]
    },
];

function Copyright() {
    return (
        <Typography variant="body2" color="gray" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/">
                {process.env.REACT_APP_APP_NAME}
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

function PrivateRoute({ children, ...rest }) {
    let location = useLocation();
    const { accessToken } = useSelector(state => state.general);
    return (
        accessToken ? (
            children
        ) : (
            <Navigate to="/login" state={{ from: location }} replace />
        )
    );
}


export default function App(props) {
    const { accessToken, permissions } = useSelector(state => state.general);

    const Wrapper = (children, sidebar) => (
        <div>
            <NotificationLoadingProvider>
                <div>
                    {
                        sidebar ?
                            <Sidebar routeProps={routes} content={
                                <>
                                    <Container maxWidth="lg">
                                        {children}
                                        <Box pt={2} pb={5}>
                                            <Copyright />
                                        </Box>
                                    </Container>


                                </>
                            } />
                            :
                            <>
                                {children}
                                <Box pt={2} pb={5}>
                                    <Copyright />
                                </Box>
                            </>
                    }
                </div>
                <NotificationLoading />
            </NotificationLoadingProvider>
        </div>
    );

    return (
        <Fragment>
            <div>
                <Routes>
                    <Route path="/login" element={accessToken ? <Navigate replace to="/dashboard" /> : Wrapper(<Login />, false)} />
                    <Route path="/forget-password" element={Wrapper(<ForgetPassword />, false)} />
                    <Route path="/reset-password" element={Wrapper(<ResetPassword />, false)} />
                    {
                        routes.map(category => {
                            return (
                                _.map(category.children, (route) => {
                                    let fulfilledAllPermission = true;
                                    if (_.size(route.permission) > 0) {
                                        _.map(route.permission, (contentPermission) => {
                                            if (!_.includes(permissions, contentPermission)) {
                                                fulfilledAllPermission = false;
                                            }
                                        })
                                    }
                                    return <Route
                                        key={route.path}
                                        path={route.path}
                                        element={fulfilledAllPermission ? <PrivateRoute>
                                            {Wrapper(route.component, true)}
                                        </PrivateRoute> : <Navigate replace to="/dashboard" />} />
                                })
                            )
                        })
                    }
                    <Route path="*" element={accessToken ? <Navigate replace to="/dashboard" /> : <Navigate replace to="/login" />} />
                </Routes>
            </div>
        </Fragment>
    );
};